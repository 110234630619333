import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  APPLY_FILTERS:                                      type('[Engage] Apply Filters'),
  CALENDAR_EVENTS_ERROR:                              type('[Engage] Calendar Events Error'),
  CALENDAR_EVENTS_FOUND:                              type('[Engage] Calendar Events Found'),
  CANCEL_BONUS_POINT_PROMOTION_FROM_CALENDAR:         type('[Engage] Cancel Bonus Point Promotion From Calendar'),
  CANCEL_BONUS_POINT_PROMOTION_FROM_CALENDAR_ERROR:   type('[Engage] Cancel Bonus Point Promotion From Calendar Error'),
  CANCEL_BONUS_POINT_PROMOTION_FROM_CALENDAR_SUCCESS: type('[Engage] Cancel Bonus Point Promotion From Calendar Success'),
  CANCEL_COMMUNITY_CARE_EVENT_FROM_CALENDAR:          type('[Engage] Cancel Community Care Event From Calendar'),
  CANCEL_COMMUNITY_CARE_EVENT_FROM_CALENDAR_ERROR:    type('[Engage] Cancel Community Care Event From Calendar Error'),
  CANCEL_COMMUNITY_CARE_EVENT_FROM_CALENDAR_SUCCESS:  type('[Engage] Cancel Community Care Event From Calendar Success'),
  CANCEL_EMAIL:                                       type('[Engage] Cancel Email'),
  CANCEL_EMAIL_ERROR:                                 type('[Engage] Cancel Email Error'),
  CANCEL_EMAIL_SUCCESS:                               type('[Engage] Cancel Email Success'),
  CANCEL_EVENT:                                       type('[Engage] Cancel Event'),
  CANCEL_EVENT_ERROR:                                 type('[Engage] Cancel Event Error'),
  CANCEL_EVENT_SUCCESS:                               type('[Engage] Cancel Event Success'),
  CANCEL_FREE_ITEM_IN_CART_FROM_CALENDAR:             type('[Engage] Cancel Free Item In Cart From Calendar'),
  CANCEL_FREE_ITEM_IN_CART_FROM_CALENDAR_ERROR:       type('[Engage] Cancel Free Item In Cart From Calendar Error'),
  CANCEL_FREE_ITEM_IN_CART_FROM_CALENDAR_SUCCESS:     type('[Engage] Cancel Free Item In Cart From Calendar Success'),
  CANCEL_REWARD:                                      type('[Engage] Cancel Reward'),
  CANCEL_REWARD_ERROR:                                type('[Engage] Cancel Reward Error'),
  CANCEL_REWARD_SUCCESS:                              type('[Engage] Cancel Reward Success'),
  CANCEL_THIRD_PARTY_PROMOTION:                       type('[Engage] Cancel Third Party Promotion'),
  CANCEL_THIRD_PARTY_PROMOTION_ERROR:                 type('[Engage] Cancel Third Party Promotion Error'),
  CANCEL_THIRD_PARTY_PROMOTION_SUCCESS:               type('[Engage] Cancel Third Party Promotion Success'),
  CHANGE_FILTER_PAGE:                                 type('[Engage] Change Filter Page'),
  CHANGE_FILTER_PAGE_SIZE:                            type('[Engage] Change Filter Page Size'),
  CHANGE_FILTER_SORT:                                 type('[Engage] Change Filter Sort'),
  CHOOSE_NEW_REWARD_FOR_FAILED:                       type('[Engage] Choose New Reward For Failed'),
  CLEAR_CACHE_ERROR:                                  type('[Engage] Clear Cache Error'),
  CLEAR_CACHE_SUCCESS:                                type('[Engage] Clear Cache Success'),
  DELETE_DRAFT_AFTER_SEND_SUCCESS:                    type('[Engage] Delete Draft After Send Success'),
  DELETE_EMAIL_DRAFT:                                 type('[Engage] Delete Draft'),
  DELETE_EMAIL_DRAFT_ERROR:                           type('[Engage] Delete Draft Error'),
  DELETE_EMAIL_DRAFT_SUCCESS:                         type('[Engage] Delete Draft Success'),
  EDIT_EMAIL:                                         type('[Engage] Edit Email'),
  EDIT_EMAIL_ERROR:                                   type('[Engage] Edit Email Error'),
  EDIT_EMAIL_SUCCESS:                                 type('[Engage] Edit Email Success'),
  EDIT_EVENT:                                         type('[Engage] Edit Event'),
  EDIT_EVENT_ERROR:                                   type('[Engage] Edit Event Error'),
  EDIT_EVENT_SUCCESS:                                 type('[Engage] Edit Event Success'),
  FETCH_EMAIL_DRAFT_DETAILS_ERROR:                    type('[Engage] Fetch Email Draft Details Error'),
  FETCH_EMAIL_DRAFT_DETAILS_SUCCESS:                  type('[Engage] Fetch Email Draft Details Success'),
  FETCH_EMAILS:                                       type('[Engage] Fetch Emails'),
  FETCH_EMAIL_TEMPLATES_FAILURE:                      type('[Engage] Fetch Email Templates Failure'),
  FETCH_EMAIL_TEMPLATES_SUCCESS:                      type('[Engage] Fetch Email Templates Success'),
  FETCH_EVENTS:                                       type('[Engage] Fetch Events'),
  FETCH_EVENT_TEMPLATE_HTML_FAILURE:                  type('[Engage] Fetch Event Template Html Failure'),
  FETCH_EVENT_TEMPLATE_HTML_SUCCESS:                  type('[Engage] Fetch Event Template Html Success'),
  FETCH_EVENT_TEMPLATES_FAILURE:                      type('[Engage] Fetch Event Templates Failure'),
  FETCH_EVENT_TEMPLATES_SUCCESS:                      type('[Engage] Fetch Event Templates Success'),
  FETCH_REWARDS:                                      type('[Engage] Fetch Reward Templates'),
  FETCH_REWARDS_FAILURE:                              type('[Engage] Fetch Reward Templates Failure'),
  FETCH_REWARDS_SUCCESS:                              type('[Engage] Fetch Reward Templates Success'),
  FETCH_TEMPLATE_DETAILS_FAILURE:                     type('[Engage] Fetch Template Details Failure'),
  FETCH_TEMPLATE_DETAILS_SUCCESS:                     type('[Engage] Fetch Template Details Success'),
  FIND_FILTER_DISPLAY_CUSTOMERS_ERROR:                type('[Engage] Find Filter Display Customers Error'),
  FIND_FILTER_DISPLAY_CUSTOMERS_SUCCESS:              type('[Engage] Find Filter Display Customers Success'),
  GET_CUSTOMERS_BY_ID_ERROR:                          type('[Engage] Get Customers By Id Error'),
  GET_CUSTOMERS_BY_ID_SUCCESS:                        type('[Engage] Get Customers By Id Success'),
  GET_DEFAULT_FILTER_CUSTOMERS_FAILURE:               type('[Engage] Get Default Filter Customers Failure'),
  GET_DEFAULT_FILTER_CUSTOMERS_SUCCESS:               type('[Engage] Get Default Filter Customers Success'),
  GET_DEFAULT_FILTERS_FAILURE:                        type('[Engage] Get Default Filters Failure'),
  GET_DEFAULT_FILTERS_SUCCESS:                        type('[Engage] Get Default Filters Success'),
  GET_EXCLUDED_CUSTOMERS_BY_ID_ERROR:                 type('[Engage] Get Excluded Customers By Id Error'),
  GET_EXCLUDED_CUSTOMERS_BY_ID_SUCCESS:               type('[Engage] Get Excluded Customers By Id Success'),
  GET_SAVED_FILTER_CUSTOMERS:                         type('[Engage] Get Saved Filter Customers'),
  GET_SAVED_FILTER_CUSTOMERS_FAILURE:                 type('[Engage] Get Saved Filter Customers Failure'),
  GET_SAVED_FILTER_CUSTOMERS_SUCCESS:                 type('[Engage] Get Saved Filter Customers Success'),
  GET_SAVED_FILTERS:                                  type('[Engage] Get Saved Filters'),
  GET_SAVED_FILTERS_FAILURE:                          type('[Engage] Get Saved Filters Failure'),
  GET_SAVED_FILTERS_SUCCESS:                          type('[Engage] Get Saved Filters Success'),
  GET_SELECTED_FILTER_DETAILS_ERROR:                  type('[Engage] Get Selected Filter Details Error'),
  GET_SELECTED_FILTER_DETAILS_SUCCESS:                type('[Engage] Get Selected Filter Details Success'),
  GET_SELECTED_INDIVIDUALS_DETAILS:                   type('[Engage] Get Selected Individuals Details'),
  GET_SELECTED_INDIVIDUALS_DETAILS_FAILURE:           type('[Engage] Get Selected Individuals Details Failure'),
  GO_TO_EMAIL_EDIT:                                   type('[Engage] Go To Email Edit'),
  GO_TO_ENGAGE_PAGE:                                  type('[Engage] Go To Engage Page'),
  GO_TO_EVENT_EDIT:                                   type('[Engage] Go To Event Edit'),
  GO_TO_OVERLAPPING_ENGAGEMENT:                       type('[Engage] Go To Overlapping Engagement Page'),
  RECIPIENTS_SEARCH_TERM_CHANGED:                     type('[Engage] Recipients Search Term Changed'),
  RETRY_FAILED_REWARD_SEND:                           type('[Engage] Retry Failed Reward Send'),
  REWARD_HTML_AND_METADATA_FOUND:                     type('[Engage] Reward HTML And Metadata Found'),
  SAVE_EMAIL_DRAFT:                                   type('[Engage] Save Draft'),
  SAVE_EMAIL_DRAFT_ERROR:                             type('[Engage] Save Draft Error'),
  SAVE_EMAIL_DRAFT_SUCCESS:                           type('[Engage] Save Draft Success'),
  SELECT_ALL_CUSTOMERS:                               type('[Engage] Select All Customers'),
  SELECT_EMAIL_DRAFT:                                 type('[Engage] Select Email Draft'),
  SELECT_ENGAGE_EMAIL:                                type('[Engage] Select Engage Email'),
  SELECT_ENGAGE_EVENT:                                type('[Engage] Select Engage Event'),
  SELECT_ENGAGE_REWARD:                               type('[Engage] Select Engage Reward'),
  SELECT_EVENT_END_DATE:                              type('[Engage] Select Event End Date'),
  SELECT_EVENT_START_DATE:                            type('[Engage] Select Event Start Date'),
  SELECT_EVENT_START_TIME:                            type('[Engage] Select Event Start Time'),
  SELECT_REWARD_DATES:                                type('[Engage] Select Reward Dates'),
  SEND_DRAFT:                                         type('[Engage] Send Draft'),
  SEND_DRAFT_SUCCESS:                                 type('[Engage] Send Draft Success'),
  SEND_EMAIL:                                         type('[Engage] Send Email'),
  SEND_EMAIL_FAILURE:                                 type('[Engage] Send Email Failure'),
  SEND_EMAIL_SUCCESS:                                 type('[Engage] Send Email Success'),
  SEND_EVENT:                                         type('[Engage] Send Event'),
  SEND_EVENT_FAILURE:                                 type('[Engage] Send Event Failure'),
  SEND_EVENT_SUCCESS:                                 type('[Engage] Send Event Success'),
  SEND_REWARD:                                        type('[Engage] Send Reward'),
  SEND_REWARD_FAILURE:                                type('[Engage] Send Reward Failure'),
  SEND_REWARD_SUCCESS:                                type('[Engage] Send Reward Success'),
  SET_GROUP_SEND_OPTION:                              type('[Engage] Set Group Send Option'),
  SET_SELECTED_DISPLAY_FILTERS:                       type('[Engage] Set Selected Display Filters'),
  SET_SELECTED_TAB_INDEX:                             type('[Engage] Set Selected Tab Index'),
  UPDATE_CAMPAIGN_NAME:                               type('[Engage] Update Campaign Name'),
  UPDATE_EMAIL_SEND_DATE:                             type('[Engage] Update Email Send Date'),
  UPDATE_ENGAGEMENT:                                  type('[Engage] Update Engagement'),
  UPDATE_LIMITED_RESERVATIONS:                        type('[Engage] Update Limited Reservations'),
  UPDATE_NON_RSVP_REMINDER:                           type('[Engage] Update Non Rsvp Reminder'),
  UPDATE_RECRUITMENT_MODULE_ENABLED:                  type('[Engage] Update Recruitment Module Enabled'),
  UPDATE_RECRUITMENT_MODULE_LINK:                     type('[Engage] Update Recruitment Module Link'),
  UPDATE_REMINDER_EMAIL_SEND:                         type('[Engage] Update Reminder Email Send'),
  UPDATE_RSVP_REMINDER:                               type('[Engage] Update Rsvp Reminder'),
  UPDATE_RSVP_REQUIRED:                               type('[Engage] Update Rsvp Required'),
  UPDATE_SELECTED_CUSTOMERS_AND_FILTERS:              type('[Engage] Update Selected Customers and Filters'),
  UPDATE_SELECTED_TAB_INDEX_FOR_HTML_PREVIEW:         type('[Engage] Update Selected Tab Index For HTML Preview'),
  UPDATE_SEND_IMMEDIATE:                              type('[Engage] Update Send Immediate'),
  UPDATE_SEND_OPERATOR_IMAGE:                         type('[Engage] Update Send Operator Image'),
  UPDATE_THEME:                                       type('[Engage] Update Theme'),
  VIEW_ON_CALENDAR:                                   type('[Engage] View On Calendar')
};

export class ApplyFilters implements Action {
  public type = ActionTypes.APPLY_FILTERS;
  constructor(public payload: any = '') { }
}

export class CalendarEventsError implements Action {
  public type = ActionTypes.CALENDAR_EVENTS_ERROR;
  constructor(public payload: any = '') { }
}

export class CalendarEventsFound implements Action {
  public type = ActionTypes.CALENDAR_EVENTS_FOUND;
  constructor(public payload: any = '') { }
}

export class CancelBonusPointPromotionFromCalendar implements Action {
  public type = ActionTypes.CANCEL_BONUS_POINT_PROMOTION_FROM_CALENDAR;
  constructor(public payload: any = '') { }
}

export class CancelBonusPointPromotionFromCalendarError implements Action {
  public type = ActionTypes.CANCEL_BONUS_POINT_PROMOTION_FROM_CALENDAR_ERROR;
  constructor(public payload: any = '') { }
}

export class CancelBonusPointPromotionFromCalendarSuccess implements Action {
  public type = ActionTypes.CANCEL_BONUS_POINT_PROMOTION_FROM_CALENDAR_SUCCESS;
  constructor(public payload: any = '') { }
}

export class CancelCommunityCareEventFromCalendar implements Action {
  public type = ActionTypes.CANCEL_COMMUNITY_CARE_EVENT_FROM_CALENDAR;
  constructor(public payload: any = '') { }
}

export class CancelCommunityCareEventFromCalendarError implements Action {
  public type = ActionTypes.CANCEL_COMMUNITY_CARE_EVENT_FROM_CALENDAR_ERROR;
  constructor(public payload: any = '') { }
}

export class CancelCommunityCareEventFromCalendarSuccess implements Action {
  public type = ActionTypes.CANCEL_COMMUNITY_CARE_EVENT_FROM_CALENDAR_SUCCESS;
  constructor(public payload: any = '') { }
}

export class CancelEmail implements Action {
  public type = ActionTypes.CANCEL_EMAIL;
  constructor(public payload: any = '') { }
}

export class CancelEmailError implements Action {
  public type = ActionTypes.CANCEL_EMAIL_ERROR;
  constructor(public payload: any = '') { }
}

export class CancelEmailSuccess implements Action {
  public type = ActionTypes.CANCEL_EMAIL_SUCCESS;
  constructor(public payload: any = '') { }
}

export class CancelEvent implements Action {
  public type = ActionTypes.CANCEL_EVENT;
  constructor(public payload: any = '') { }
}

export class CancelEventError implements Action {
  public type = ActionTypes.CANCEL_EVENT_ERROR;
  constructor(public payload: any = '') { }
}

export class CancelEventSuccess implements Action {
  public type = ActionTypes.CANCEL_EVENT_SUCCESS;
  constructor(public payload: any = '') { }
}

export class CancelFreeItemInCartFromCalendar implements Action {
  public type = ActionTypes.CANCEL_FREE_ITEM_IN_CART_FROM_CALENDAR;
  constructor(public payload: any = '') { }
}

export class CancelFreeItemInCartFromCalendarError implements Action {
  public type = ActionTypes.CANCEL_FREE_ITEM_IN_CART_FROM_CALENDAR_ERROR;
  constructor(public payload: any = '') { }
}

export class CancelFreeItemInCartFromCalendarSuccess implements Action {
  public type = ActionTypes.CANCEL_FREE_ITEM_IN_CART_FROM_CALENDAR_SUCCESS;
  constructor(public payload: any = '') { }
}

export class CancelReward implements Action {
  public type = ActionTypes.CANCEL_REWARD;
  constructor(public payload: any = '') { }
}

export class CancelRewardError implements Action {
  public type = ActionTypes.CANCEL_REWARD_ERROR;
  constructor(public payload: any = '') { }
}

export class CancelRewardSuccess implements Action {
  public type = ActionTypes.CANCEL_REWARD_SUCCESS;
  constructor(public payload: any = '') { }
}

export class CancelThirdPartyPromotion implements Action {
  public type = ActionTypes.CANCEL_THIRD_PARTY_PROMOTION;
  constructor(public payload: any = '') { }
}

export class CancelThirdPartyPromotionError implements Action {
  public type = ActionTypes.CANCEL_THIRD_PARTY_PROMOTION_ERROR;
  constructor(public payload: any = '') { }
}

export class CancelThirdPartyPromotionSuccess implements Action {
  public type = ActionTypes.CANCEL_THIRD_PARTY_PROMOTION_SUCCESS;
  constructor(public payload: any = '') { }
}

export class ChangeFilterPage implements Action {
  public type = ActionTypes.CHANGE_FILTER_PAGE;
  constructor(public payload: any) { }
}

export class ChangeFilterPageSize implements Action {
  public type = ActionTypes.CHANGE_FILTER_PAGE_SIZE;
  constructor(public payload: any) { }
}

export class ChangeFilterSort implements Action {
  public type = ActionTypes.CHANGE_FILTER_SORT;
  constructor(public payload: any) { }
}

export class ChooseNewRewardForFailed implements Action {
  public type = ActionTypes.CHOOSE_NEW_REWARD_FOR_FAILED;
  constructor(public payload: any = '') { }
}

export class ClearCacheError implements Action {
  public type = ActionTypes.CLEAR_CACHE_ERROR;
  constructor(public payload: any) { }
}

export class ClearCacheSuccess implements Action {
  public type = ActionTypes.CLEAR_CACHE_SUCCESS;
  constructor(public payload: any = '') { }
}

export class DeleteDraftAfterSendSuccess implements Action {
  public type = ActionTypes.DELETE_DRAFT_AFTER_SEND_SUCCESS;
  constructor(public payload: any) { }
}

export class DeleteEmailDraft implements Action {
  public type = ActionTypes.DELETE_EMAIL_DRAFT;
  constructor(public payload: any = '') { }
}

export class DeleteEmailDraftError implements Action {
  public type = ActionTypes.DELETE_EMAIL_DRAFT_ERROR;
  constructor(public payload: any = '') { }
}

export class DeleteEmailDraftSuccess implements Action {
  public type = ActionTypes.DELETE_EMAIL_DRAFT_SUCCESS;
  constructor(public payload: any = '') { }
}

export class EditEmail implements Action {
  public type = ActionTypes.EDIT_EMAIL;
  constructor(public payload: any = '') { }
}

export class EditEmailError implements Action {
  public type = ActionTypes.EDIT_EMAIL_ERROR;
  constructor(public payload: any = '') { }
}

export class EditEmailSuccess implements Action {
  public type = ActionTypes.EDIT_EMAIL_SUCCESS;
  constructor(public payload: any = '') { }
}

export class EditEvent implements Action {
  public type = ActionTypes.EDIT_EVENT;
  constructor(public payload: any = '') { }
}

export class EditEventError implements Action {
  public type = ActionTypes.EDIT_EVENT_ERROR;
  constructor(public payload: any = '') { }
}

export class EditEventSuccess implements Action {
  public type = ActionTypes.EDIT_EVENT_SUCCESS;
  constructor(public payload: any = '') { }
}

export class FetchEmailDraftError implements Action {
  public type = ActionTypes.FETCH_EMAIL_DRAFT_DETAILS_ERROR;
  constructor(public payload: any) { }
}

export class FetchEmailDraftSuccess implements Action {
  public type = ActionTypes.FETCH_EMAIL_DRAFT_DETAILS_SUCCESS;
  constructor(public payload: any) { }
}

export class FetchEmailTemplatesFailure implements Action {
  public type = ActionTypes.FETCH_EMAIL_TEMPLATES_FAILURE;
  constructor(public payload: any = '') { }
}

export class FetchEmailTemplatesSuccess implements Action {
  public type = ActionTypes.FETCH_EMAIL_TEMPLATES_SUCCESS;
  constructor(public payload: any = '') { }
}

export class FetchEventTemplateHtmlFailure implements Action {
  public type = ActionTypes.FETCH_EVENT_TEMPLATE_HTML_FAILURE;
  constructor(public payload: any = '') { }
}

export class FetchEventTemplateHtmlSuccess implements Action {
  public type = ActionTypes.FETCH_EVENT_TEMPLATE_HTML_SUCCESS;
  constructor(public payload: any = '') { }
}

export class FetchEventTemplatesFailure implements Action {
  public type = ActionTypes.FETCH_EVENT_TEMPLATES_FAILURE;
  constructor(public payload: any = '') { }
}

export class FetchEventTemplatesSuccess implements Action {
  public type = ActionTypes.FETCH_EVENT_TEMPLATES_SUCCESS;
  constructor(public payload: any = '') { }
}

export class FetchRewards implements Action {
  public type = ActionTypes.FETCH_REWARDS;
  constructor(public payload: any = '') { }
}

export class FetchRewardsFailure implements Action {
  public type = ActionTypes.FETCH_REWARDS_FAILURE;
  constructor(public payload: any = '') { }
}

export class FetchRewardsSuccess implements Action {
  public type = ActionTypes.FETCH_REWARDS_SUCCESS;
  constructor(public payload: any = '') { }
}

export class FetchTemplateDetailsFailure implements Action {
  public type = ActionTypes.FETCH_TEMPLATE_DETAILS_FAILURE;
  constructor(public payload: any = '') { }
}

export class FetchTemplateDetailsSuccess implements Action {
  public type = ActionTypes.FETCH_TEMPLATE_DETAILS_SUCCESS;
  constructor(public payload: any = '') { }
}

export class FindFilterDisplayCustomersError implements Action {
  public type = ActionTypes.FIND_FILTER_DISPLAY_CUSTOMERS_ERROR;
  constructor(public payload: any) { }
}

export class FindFilterDisplayCustomersSuccess implements Action {
  public type = ActionTypes.FIND_FILTER_DISPLAY_CUSTOMERS_SUCCESS;
  constructor(public payload: any) { }
}

export class GetCustomersByIdError implements Action {
  public type = ActionTypes.GET_CUSTOMERS_BY_ID_ERROR;
  constructor(public payload: any) { }
}

export class GetCustomersByIdSuccess implements Action {
  public type = ActionTypes.GET_CUSTOMERS_BY_ID_SUCCESS;
  constructor(public payload: any) { }
}

export class GetExcludedCustomersByIdError implements Action {
  public type = ActionTypes.GET_EXCLUDED_CUSTOMERS_BY_ID_ERROR;
  constructor(public payload: any) { }
}

export class GetExcludedCustomersByIdSuccess implements Action {
  public type = ActionTypes.GET_EXCLUDED_CUSTOMERS_BY_ID_SUCCESS;
  constructor(public payload: any) { }
}

export class GetSavedFilterCustomers implements Action {
  public type = ActionTypes.GET_SAVED_FILTER_CUSTOMERS;
  constructor(public payload: any) { }
}

export class GetSavedFilterCustomersFailure implements Action {
  public type = ActionTypes.GET_SAVED_FILTER_CUSTOMERS_FAILURE;
  constructor(public payload: any) { }
}

export class GetSavedFilterCustomersSuccess implements Action {
  public type = ActionTypes.GET_SAVED_FILTER_CUSTOMERS_SUCCESS;
  constructor(public payload: any) { }
}

export class GetSavedFilters implements Action {
  public type = ActionTypes.GET_SAVED_FILTERS;
  constructor(public payload: any = '') { }
}

export class GetSavedFiltersFailure implements Action {
  public type = ActionTypes.GET_SAVED_FILTERS_FAILURE;
  constructor(public payload: any = '') { }
}

export class GetSavedFiltersSuccess implements Action {
  public type = ActionTypes.GET_SAVED_FILTERS_SUCCESS;
  constructor(public payload: any = '') { }
}

export class GetSelectedFilterDetailsError implements Action {
  public type = ActionTypes.GET_SELECTED_FILTER_DETAILS_ERROR;
  constructor(public payload: any = '') { }
}

export class GetSelectedFilterDetailsSuccess implements Action {
  public type = ActionTypes.GET_SELECTED_FILTER_DETAILS_SUCCESS;
  constructor(public payload: any = '') { }
}

export class GetSelectedIndividualsDetails implements Action {
  public type = ActionTypes.GET_SELECTED_INDIVIDUALS_DETAILS;
  constructor(public payload: any = '') { }
}

export class GetSelectedIndividualsDetailsFailure implements Action {
  public type = ActionTypes.GET_SELECTED_INDIVIDUALS_DETAILS_FAILURE;
  constructor(public payload: any = '') { }
}

export class GoToEmailEdit implements Action {
  public type = ActionTypes.GO_TO_EMAIL_EDIT;
  constructor(public payload: any = '') { }
}

export class GoToEventEdit implements Action {
  public type = ActionTypes.GO_TO_EVENT_EDIT;
  constructor(public payload: any = '') { }
}

export class GoToEngagePage implements Action {
  public type = ActionTypes.GO_TO_ENGAGE_PAGE;
  constructor(public payload: any = '') { }
}

export class GoToOverLappingEngagement implements Action {
  public type = ActionTypes.GO_TO_OVERLAPPING_ENGAGEMENT;
  constructor(public payload: any = '') { }
}

export class RecipientsSearchTermChanged implements Action {
  public type = ActionTypes.RECIPIENTS_SEARCH_TERM_CHANGED;
  constructor(public payload: any) { }
}

export class RetryFailedRewardSend implements Action {
  public type = ActionTypes.RETRY_FAILED_REWARD_SEND;
  constructor(public payload: any = '') { }
}

export class RewardHtmlAndMetadataFound implements Action {
  public type = ActionTypes.REWARD_HTML_AND_METADATA_FOUND;
  constructor(public payload: any = '') { }
}

export class SaveEmailDraft implements Action {
  public type = ActionTypes.SAVE_EMAIL_DRAFT;
  constructor(public payload: any = '') { }
}

export class SaveEmailDraftError implements Action {
  public type = ActionTypes.SAVE_EMAIL_DRAFT_ERROR;
  constructor(public payload: any = '') { }
}

export class SaveEmailDraftSuccess implements Action {
  public type = ActionTypes.SAVE_EMAIL_DRAFT_SUCCESS;
  constructor(public payload: any = '') { }
}

export class SelectAllCustomers implements Action {
  public type = ActionTypes.SELECT_ALL_CUSTOMERS;
  constructor(public payload: any) { }
}

export class SelectEmailDraft implements Action {
  public type = ActionTypes.SELECT_EMAIL_DRAFT;
  constructor(public payload: any) { }
}

export class SelectEngageEmail implements Action {
  public type = ActionTypes.SELECT_ENGAGE_EMAIL;
  constructor(public payload: any = '') { }
}

export class SelectEngageEvent implements Action {
  public type = ActionTypes.SELECT_ENGAGE_EVENT;
  constructor(public payload: any = '') { }
}

export class SelectEngageReward implements Action {
  public type = ActionTypes.SELECT_ENGAGE_REWARD;
  constructor(public payload: any = '') { }
}

export class SelectEventEndDate implements Action {
  public type = ActionTypes.SELECT_EVENT_END_DATE;
  constructor(public payload: any = '') { }
}

export class SelectEventStartDate implements Action {
  public type = ActionTypes.SELECT_EVENT_START_DATE;
  constructor(public payload: any = '') { }
}

export class SelectEventStartTime implements Action {
  public type = ActionTypes.SELECT_EVENT_START_TIME;
  constructor(public payload: any = '') { }
}

export class SelectRewardDates implements Action {
  public type = ActionTypes.SELECT_REWARD_DATES;
  constructor(public payload: any = '') { }
}

export class SendDraft implements Action {
  public type = ActionTypes.SEND_DRAFT;
  constructor(public payload: any = '') { }
}

export class SendDraftSuccess implements Action {
  public type = ActionTypes.SEND_DRAFT_SUCCESS;
  constructor(public payload: any) { }
}

export class SendEmail implements Action {
  public type = ActionTypes.SEND_EMAIL;
  constructor(public payload: any = '') { }
}

export class SendEmailFailure implements Action {
  public type = ActionTypes.SEND_EMAIL_FAILURE;
  constructor(public payload: any = '') { }
}

export class SendEmailSuccess implements Action {
  public type = ActionTypes.SEND_EMAIL_SUCCESS;
  constructor(public payload: any = '') { }
}

export class SendEvent implements Action {
  public type = ActionTypes.SEND_EVENT;
  constructor(public payload: any = '') { }
}

export class SendEventFailure implements Action {
  public type = ActionTypes.SEND_EVENT_FAILURE;
  constructor(public payload: any = '') { }
}

export class SendEventSuccess implements Action {
  public type = ActionTypes.SEND_EVENT_SUCCESS;
  constructor(public payload: any = '') { }
}

export class SendReward implements Action {
  public type = ActionTypes.SEND_REWARD;
  constructor(public payload: any = '') { }
}

export class SendRewardFailure implements Action {
  public type = ActionTypes.SEND_REWARD_FAILURE;
  constructor(public payload: any = '') { }
}

export class SendRewardSuccess implements Action {
  public type = ActionTypes.SEND_REWARD_SUCCESS;
  constructor(public payload: any = '') { }
}

export class SetGroupSendOption implements Action {
  public type = ActionTypes.SET_GROUP_SEND_OPTION;
  constructor(public payload: any) { }
}

export class SetSelectedDisplayFilters implements Action {
  public type = ActionTypes.SET_SELECTED_DISPLAY_FILTERS;
  constructor(public payload: any) { }
}

export class SetSelectedTabIndex implements Action {
  public type = ActionTypes.SET_SELECTED_TAB_INDEX;
  constructor(public payload: number) { }
}

export class UpdateCampaignName implements Action {
  public type = ActionTypes.UPDATE_CAMPAIGN_NAME;
  constructor(public payload: any = '') { }
}

export class UpdateEmailSendDate implements Action {
  public type = ActionTypes.UPDATE_EMAIL_SEND_DATE;
  constructor(public payload: any = '') { }
}

export class UpdateEngagement implements Action {
  public type = ActionTypes.UPDATE_ENGAGEMENT;
  constructor(public payload: any = '') { }
}

export class UpdateLimitedReservations implements Action {
  public type = ActionTypes.UPDATE_LIMITED_RESERVATIONS;
  constructor(public payload: any = '') { }
}

export class UpdateNonRsvpReminder implements Action {
  public type = ActionTypes.UPDATE_NON_RSVP_REMINDER;
  constructor(public payload: any = '') { }
}

export class UpdateRecruitmentModuleEnabled implements Action {
  public type = ActionTypes.UPDATE_RECRUITMENT_MODULE_ENABLED;
  constructor(public payload: any = '') { }
}

export class UpdateRecruitmentModuleLink implements Action {
  public type = ActionTypes.UPDATE_RECRUITMENT_MODULE_LINK;
  constructor(public payload: any = '') { }
}

export class UpdateReminderEmailSend implements Action {
  public type = ActionTypes.UPDATE_REMINDER_EMAIL_SEND;
  constructor(public payload: any = '') { }
}

export class UpdateRsvpReminder implements Action {
  public type = ActionTypes.UPDATE_RSVP_REMINDER;
  constructor(public payload: any = '') { }
}

export class UpdateRsvpRequired implements Action {
  public type = ActionTypes.UPDATE_RSVP_REQUIRED;
  constructor(public payload: any = '') { }
}

export class UpdateSelectedCustomersAndFilters implements Action {
  public type = ActionTypes.UPDATE_SELECTED_CUSTOMERS_AND_FILTERS;
  constructor(public payload: any = '') { }
}

export class UpdateSelectedTabIndexForHTMLPreview implements Action {
  public type = ActionTypes.UPDATE_SELECTED_TAB_INDEX_FOR_HTML_PREVIEW;
  constructor(public payload: any = '') { }
}

export class UpdateSendImmediate implements Action {
  public type = ActionTypes.UPDATE_SEND_IMMEDIATE;
  constructor(public payload: any = '') { }
}

export class UpdateSendOperatorImage implements Action {
  public type = ActionTypes.UPDATE_SEND_OPERATOR_IMAGE;
  constructor(public payload: any = '') { }
}

export class UpdateTheme implements Action {
  public type = ActionTypes.UPDATE_THEME;
  constructor(public payload: any = '') { }
}

export class ViewOnCalendar implements Action {
  public type = ActionTypes.VIEW_ON_CALENDAR;
  constructor(public payload: any = '') { }
}

export type Actions
  = ApplyFilters
  | CalendarEventsFound
  | CalendarEventsError
  | CancelBonusPointPromotionFromCalendar
  | CancelBonusPointPromotionFromCalendarError
  | CancelBonusPointPromotionFromCalendarSuccess
  | CancelCommunityCareEventFromCalendar
  | CancelCommunityCareEventFromCalendarError
  | CancelCommunityCareEventFromCalendarSuccess
  | CancelEmail
  | CancelEmailError
  | CancelEmailSuccess
  | CancelEvent
  | CancelEventError
  | CancelEventSuccess
  | CancelReward
  | CancelRewardError
  | CancelRewardSuccess
  | CancelThirdPartyPromotion
  | CancelThirdPartyPromotionError
  | CancelThirdPartyPromotionSuccess
  | ChangeFilterPage
  | ChangeFilterPageSize
  | ChangeFilterSort
  | ChooseNewRewardForFailed
  | DeleteDraftAfterSendSuccess
  | DeleteEmailDraft
  | DeleteEmailDraftError
  | DeleteEmailDraftSuccess
  | EditEmail
  | EditEmailError
  | EditEmailSuccess
  | EditEvent
  | EditEventError
  | EditEventSuccess
  | FetchEmailDraftError
  | FetchEmailDraftSuccess
  | FetchEmailTemplatesFailure
  | FetchEmailTemplatesSuccess
  | FetchEventTemplateHtmlFailure
  | FetchEventTemplateHtmlSuccess
  | FetchEventTemplatesFailure
  | FetchEventTemplatesSuccess
  | FetchRewards
  | FetchRewardsFailure
  | FetchRewardsSuccess
  | FetchTemplateDetailsFailure
  | FetchTemplateDetailsSuccess
  | FindFilterDisplayCustomersError
  | FindFilterDisplayCustomersSuccess
  | GetCustomersByIdError
  | GetCustomersByIdSuccess
  | GetExcludedCustomersByIdError
  | GetExcludedCustomersByIdSuccess
  | GetSavedFilterCustomers
  | GetSavedFilterCustomersFailure
  | GetSavedFilterCustomersSuccess
  | GetSavedFilters
  | GetSavedFiltersFailure
  | GetSavedFiltersSuccess
  | GetSelectedFilterDetailsError
  | GetSelectedFilterDetailsSuccess
  | GetSelectedIndividualsDetailsFailure
  | GetSelectedIndividualsDetails
  | GoToEmailEdit
  | GoToEngagePage
  | GoToEventEdit
  | GoToOverLappingEngagement
  | RecipientsSearchTermChanged
  | RetryFailedRewardSend
  | RewardHtmlAndMetadataFound
  | SaveEmailDraft
  | SaveEmailDraftError
  | SaveEmailDraftSuccess
  | SelectAllCustomers
  | SelectEmailDraft
  | SelectEngageEmail
  | SelectEngageEvent
  | SelectEngageReward
  | SelectEventEndDate
  | SelectEventStartDate
  | SelectEventStartTime
  | SelectRewardDates
  | SendDraft
  | SendDraftSuccess
  | SendEmail
  | SendEmailFailure
  | SendEmailSuccess
  | SendEvent
  | SendEventFailure
  | SendEventSuccess
  | SendReward
  | SendRewardFailure
  | SendRewardSuccess
  | SetGroupSendOption
  | SetSelectedDisplayFilters
  | SetSelectedTabIndex
  | UpdateCampaignName
  | UpdateEmailSendDate
  | UpdateEngagement
  | UpdateLimitedReservations
  | UpdateNonRsvpReminder
  | UpdateRecruitmentModuleEnabled
  | UpdateRecruitmentModuleLink
  | UpdateReminderEmailSend
  | UpdateRsvpReminder
  | UpdateRsvpRequired
  | UpdateSelectedCustomersAndFilters
  | UpdateSelectedTabIndexForHTMLPreview
  | UpdateSendImmediate
  | UpdateSendOperatorImage
  | UpdateTheme
  | ViewOnCalendar;
