import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { catchError, debounceTime, filter, map, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { EngageService } from '../engage/engage.service';
import { CalendarService } from '../calendar/calendar.service';
import * as calendar from '../actions/calendar';
import * as user from '../actions/user';
import * as engagement from '../actions/engagement';
import * as fromRoot from '../reducers';
import Bugsnag from '@bugsnag/js';

@Injectable()
export class CalendarEffects {
  @Effect()
  public clearCalendarSearchOnLocationSwitch: Observable<Action> = this.actions$.pipe(
    ofType(user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS),
    switchMap(() => of(new calendar.CalendarSwitchLocations())),
  );

  @Effect()
  public fetchCalendarEventsOnLocationSwitch: Observable<Action> = this.actions$.pipe(
      ofType(calendar.ActionTypes.CALENDAR_SWITCH_LOCATIONS),
      filter(() => this.router.url === '/calendar'),
      switchMap(() => this.store.select(fromRoot.getCalendarEventsPayload).pipe(take(1))),
      switchMap((payload) => this.cs.getCalendarEvents(payload)),
      map((res) => new calendar.FetchCalendarEventsSuccess(res)),
      catchError((error) => {
        Bugsnag.notify(new Error('Fetch Calendar Events Failed'));
        return of(new calendar.FetchCalendarEventsFailure(error));
      })
  )

  @Effect()
  public fetchCalendarEvents$: Observable<Action> = this.actions$.pipe(
    ofType(
      engagement.ActionTypes.SEND_COMMUNITY_CARE_EVENT_SUCCESS,
      engagement.ActionTypes.STOP_COMMUNITY_CARE_EVENT_SUCCESS,
      engagement.ActionTypes.SEND_THIRD_PARTY_PROMOTION_SUCCESS,
      engagement.ActionTypes.SEND_FREE_IN_CART_SUCCESS,
      engagement.ActionTypes.CANCEL_THIRD_PARTY_PROMOTION_SUCCESS,
      calendar.ActionTypes.CALENDAR_DATES_CHANGED
    ),
    filter(() => this.router.url === '/calendar'),
    switchMap(() => this.store.select(fromRoot.getCalendarEventsPayload).pipe(take(1))),
    switchMap((payload) => this.cs.getCalendarEvents(payload)),
    map((res) => new calendar.FetchCalendarEventsSuccess(res)),
    catchError((error) => {
      Bugsnag.notify(new Error('Fetch Calendar Events Failed'));
      return of(new calendar.FetchCalendarEventsFailure(error));
    })
  );

  @Effect()
  public fetchCalendarEventsFromToggle$: Observable<Action> = this.actions$.pipe(
    ofType(calendar.ActionTypes.CALENDAR_TOGGLED),
    map((action: any) => action.payload),
    filter((payload: any) => payload.subCategoryToggled),
    switchMap(() => this.store.select(fromRoot.getCalendarEventsPayload).pipe(take(1))),
    switchMap((payload) => this.cs.getCalendarEvents(payload)),
    map((res) => new calendar.FetchCalendarEventsSuccess(res)),
    catchError((error) => {
      Bugsnag.notify(new Error('Fetch Calendar Events Failed'));
      return of(new calendar.FetchCalendarEventsFailure(error));
    })
  );

  @Effect()
  public fetchRewardDetails$: Observable<Action> = this.actions$.pipe(
    ofType(calendar.ActionTypes.FETCH_REWARD_DETAILS),
    map((action: any) => action.payload),
    switchMap((engagementDetails) => this.es.getCalendarRewardDetails(
      engagementDetails.id,
      engagementDetails.type
    )),
    map((res) => new calendar.FetchRewardDetailsSuccess(res)),
    catchError((error) => {
      Bugsnag.notify(new Error('Fetch Reward Details Failed'));
      return of(new calendar.FetchRewardDetailsFailure(error));
    })
  );

  @Effect()
  public fetchAutomatedRewardDetails$: Observable<Action> = this.actions$.pipe(
    ofType(calendar.ActionTypes.FETCH_AUTOMATED_REWARD_DETAILS),
    map((action: any) => action.payload),
    switchMap((engagementDetails) => this.es.getCalendarAutomatedRewardDetails(
      engagementDetails.batchIds
    )),
    map((res) => new calendar.FetchAutomatedRewardDetailsSuccess(res)),
    catchError((error) => {
      Bugsnag.notify(new Error('Fetch Automated Reward Details Failed'));
      return of(new calendar.FetchAutomatedRewardDetailsFailure(error));
    })
  );

  @Effect()
  public fetchEventDetails$: Observable<any> = this.actions$.pipe(
    ofType(calendar.ActionTypes.FETCH_EVENT_DETAILS),
    map((action: any) => action.payload),
    switchMap((engagementDetails) => this.es.getCalendarEventDetails(engagementDetails.id).pipe(
      map((res) => new calendar.FetchEventDetailsSuccess(res)),
      catchError((error) => {
        Bugsnag.notify(new Error('Fetch Event Details Failed'));
        return of(new calendar.FetchEventDetailsFailure(error));
      })
    ))
  );

  @Effect()
  public fetchEmailDetails$: Observable<any> = this.actions$.pipe(
    ofType(calendar.ActionTypes.FETCH_EMAIL_DETAILS),
    map((action: any) => action.payload),
    switchMap((engagementDetails) => this.es.getCalendarEmailDetails(engagementDetails.id).pipe(
      map((res) => new calendar.FetchEmailDetailsSuccess(res)),
      catchError((error) => {
        Bugsnag.notify(new Error('Fetch Email Details Failed'));
        return of(new calendar.FetchEmailDetailsFailure(error));
      })
    ))
  );

  @Effect()
  public fetchBonusPointDetails$: Observable<any> = this.actions$.pipe(
    ofType(calendar.ActionTypes.FETCH_BONUS_POINT_DETAILS),
    map((action: any) => action.payload),
    switchMap((engagementDetails) => this.es.getCalendarBonusPointDetails(engagementDetails.id).pipe(
      map((res) => new calendar.FetchBonusPointDetailsSuccess(res)),
      catchError((error) => {
        Bugsnag.notify(new Error('Fetch Bonus Point Details Failed'));
        return of(new calendar.FetchBonusPointDetailsFailure(error));
      })
    ))
  );

  @Effect()
  public fetchBonusPointStats$: Observable<any> = this.actions$.pipe(
    ofType(calendar.ActionTypes.FETCH_BONUS_POINT_DETAILS),
    map((action: any) => action.payload),
    switchMap((engagementDetails) => this.es.getCalendarBonusPointStats(engagementDetails.id).pipe(
      map((res) => new calendar.FetchBonusPointStatsSuccess(res)),
      catchError((error) => {
        Bugsnag.notify(new Error('Fetch Bonus Point Details Failed'));
        return of(new calendar.FetchBonusPointStatsFailure(error));
      })
    ))
  );

  @Effect()
  public fetchCommunityCareDetails$: Observable<any> = this.actions$.pipe(
    ofType(calendar.ActionTypes.FETCH_COMMUNITY_CARE_DETAILS),
    map((action: any) => action.payload),
    switchMap((engagementDetails) => this.es.getCalendarCommunityCareEvent(engagementDetails.id).pipe(
      map((res) => new calendar.FetchCommunityCareDetailsSuccess(res)),
      catchError((error) => {
        Bugsnag.notify(new Error('Fetch Community Care Details Failed'));
        return of(new calendar.FetchCommunityCareDetailsFailure(error));
      })
    ))
  );

  @Effect()
  public fetchThirdPartyDetails$: Observable<any> = this.actions$.pipe(
      ofType(calendar.ActionTypes.FETCH_THIRD_PARTY_DETAILS),
      map((action: any) => action.payload),
      switchMap((engagementDetails) => this.es.getCalendarThirdPartyPromotion(engagementDetails.id).pipe(
          map((res) => new calendar.FetchThirdPartyDetailsSuccess(res)),
          catchError((error) => {
            Bugsnag.notify(new Error('Fetch Third Party Details Failed'));
            return of(new calendar.FetchThirdPartyDetailsFailure(error));
          })
      ))
  );

    @Effect()
    public fetchFreeInCartDetails$: Observable<any> = this.actions$.pipe(
        ofType(calendar.ActionTypes.FETCH_FREE_ITEM_IN_CART_DETAILS),
        map((action: any) => action.payload),
        switchMap((engagementDetails) => this.es.getCalendarFreeItemInCartPromotion(engagementDetails.id).pipe(
            map((res) => new calendar.FetchFreeItemInCartDetailsSuccess(res)),
            catchError((error) => {
                Bugsnag.notify(new Error('Fetch Free Item In Cart Details Failed'));
                return of(new calendar.FetchFreeItemInCartDetailsFailure(error));
            })
        ))
    );

    @Effect()
    public fetchFreeItemInCartStats$: Observable<any> = this.actions$.pipe(
        ofType(calendar.ActionTypes.FETCH_FREE_ITEM_IN_CART_DETAILS),
        map((action: any) => action.payload),
        switchMap((engagementDetails) => this.es.getCalendarFreeInCartStats(engagementDetails.id).pipe(
            map((res) => new calendar.FetchFreeItemInCartStatsSuccess(res)),
            catchError((error) => {
                Bugsnag.notify(new Error('Fetch Third Party Stats Failed'));
                return of(new calendar.FetchFreeItemInCartStatsFailure(error));
            })
        ))
    );

  @Effect()
  public fetchThirdPartyStats$: Observable<any> = this.actions$.pipe(
    ofType(calendar.ActionTypes.FETCH_THIRD_PARTY_DETAILS),
    map((action: any) => action.payload),
    switchMap((engagementDetails) => this.es.getCalendarThirdPartyPromotionStats(engagementDetails.id).pipe(
      map((res) => new calendar.FetchThirdPartyStatsSuccess(res)),
      catchError((error) => {
        Bugsnag.notify(new Error('Fetch Third Party Stats Failed'));
        return of(new calendar.FetchThirdPartyStatsFailure(error));
      })
    ))
  );

  @Effect()
  public fetchCalendarEventsOnSearchWithDelay: Observable<Action> = this.actions$.pipe(
    ofType(
      calendar.ActionTypes.CALENDAR_SEARCH_TERM_CHANGED,
      calendar.ActionTypes.CALENDAR_CLEAR_SEARCH
    ),
    debounceTime(1000),
    switchMap(() => this.store.select(fromRoot.getCalendarEventsPayload).pipe(take(1))),
    switchMap((payload) => this.cs.getCalendarEvents(payload)),
    map((res) => new calendar.FetchCalendarEventsSuccess(res)),
    catchError((error) => {
      Bugsnag.notify(new Error('Fetch Calendar Events Failed'));
      return of(new calendar.FetchCalendarEventsFailure(error));
    })
  );

  @Effect()
  public fetchCalendarEventsOnSearchWithoutDelay: Observable<Action> = this.actions$.pipe(
    ofType(
      calendar.ActionTypes.CALENDAR_SEARCH_CRITERIA_CHANGED,
    ),
    switchMap(() => this.store.select(fromRoot.getCalendarEventsPayload).pipe(take(1))),
    switchMap((payload) => this.cs.getCalendarEvents(payload)),
    map((res) => new calendar.FetchCalendarEventsSuccess(res)),
    catchError((error) => {
      Bugsnag.notify(new Error('Fetch Calendar Events Failed'));
      return of(new calendar.FetchCalendarEventsFailure(error));
    })
  );

  constructor(
    private actions$: Actions,
    private es: EngageService,
    private cs: CalendarService,
    private router: Router,
    private store: Store<fromRoot.State>
  ) {

  }
}
