<!--TODO: Add loading and error state when the api is hooked up-->
<mat-dialog-content *ngIf="!(isMobile$ | async)" class="service-now-report__wrapper">
  <div class="service-now-report__header">
    CARES Report #{{reportNumber}}
    <mat-icon class="service-now-report__close-report" (click)="closeReport()">close</mat-icon>
  </div>
  <div fxLayout="row" fxLayoutGap="10">
    <div fxFlex="30%">
      <div class="service-now-report__personal-details">
        <div>Customer: {{customerName}}</div>
        <div>Phone: {{customerPhoneNumber}}</div>
        <div>Email: {{customerEmail}}</div>
      </div>
      <div class="service-now-report__report-details-container">
        <div class="service-now-report__report-details-header">Contact Instruction</div>
        <div>{{reportContactVia}}</div>
        <!--TODO: Add logic to only add red styling if priority is high-->
        <div class="service-now-report__red-text">{{reportPriority}}</div>
        <div>{{reportContactRequiredTime}}</div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Occurred On</div>
        <div>{{reportOccurredOn}}</div>
        <div class="service-now-report__report-details-header">Contacted On</div>
        <div>{{reportContactedOn}}</div>
        <div class="service-now-report__report-details-header">Report Routed On</div>
        <div *ngFor="let contact of reportRoutedOn">
          <div>{{contact}}</div>
        </div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Customer Contact History</div>
        <div>Prior complaints to any Chick-fil-A: {{customerPriorComplaintsToAnyRestaurant}}</div>
        <div>Prior complaints to this Chick-fil-A:
          <!--TODO: Add link to member profile-->
          <div class="service-now-report__red-text">{{customerPriorComplaintsToThisRestaurant}} - View in Member Profile</div>
        </div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Location Information</div>
        <div>Number: {{locationNumber}}</div>
        <div class="service-now-report__report-details-header">Simple Order/Service Contact</div>
        <div>{{reportOrderServiceContact}}</div>
        <div class="service-now-report__report-details-header">Simple-Service Environment Contact</div>
        <div>{{reportServiceEnvironmentContact}}</div>
      </div>
    </div>
    <div fxFlex="70%">
      <!--TODO: Add logic to only show the next 2 data points for High priority reports-->
      <div class="service-now-report__high-priority-details">
        <div>To: {{reportTo}}</div>
        <div>CC: {{reportCC}}</div>
      </div>
      <div class="service-now-report__report-history-container">
        <div class="service-now-report__report-details-header">Active Guidance for Restaurant</div>
        <!--TODO: Add logic to only show red styling if action is overdue-->
        <div class="service-now-report__red-text">{{reportActionGuidance}}</div>
        <hr class="service-now-report__divider">
        <div fxLayout="row" fxLayoutGap="10">
          <div fxFlex="25%">
            <div class="service-now-report__report-details-header">Order Placed Via</div>
            <div>{{orderPlacedVia}}</div>
          </div>
          <div fxFlex="25%">
            <div class="service-now-report__report-details-header">Order Received Via</div>
            <div>{{orderReceivedVia}}</div>
          </div>
          <div fxFlex="25%">
            <div class="service-now-report__report-details-header">Receipt?</div>
            <div>{{receiptIncluded}}</div>
          </div>
          <div fxFlex="25%">
            <div class="service-now-report__report-details-header">Order Number</div>
            <div>{{orderNumber}}</div>
          </div>
        </div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Customer Comments</div>
        <div>{{customerComments}}</div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Approved Response Sent To Customer</div>
        <div>{{responseSentToCustomer}}</div>
        <hr class="service-now-report__divider">
        <div fxLayout="row" fxLayoutGap="10">
          <div fxFlex="45%">
            <div class="service-now-report__report-details-header">Resolution Status</div>
            <div>{{reportResolutionStatus}}</div>
            <div>Reasons CARES unable to resolve: {{reportReasonUnableToResolve}}</div>
          </div>
          <div fxFlex="10%">
            <div class="service-now-report__vertical-divider"></div>
          </div>
          <div fxFlex="45%">
            <div class="service-now-report__report-details-header">Care Offer Status</div>
            <div>{{reportOfferStatus}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-content *ngIf="isMobile$ | async" class="service-now-report__mobile-wrapper">
  <div class="service-now-report__header">
    CARES Report #{{reportNumber}}
    <mat-icon class="service-now-report__close-report" (click)="closeReport()">close</mat-icon>
  </div>
    <div class="service-now-report__personal-details">
      <div>Customer: {{customerName}}</div>
      <div>Phone: {{customerPhoneNumber}}</div>
      <div>Email: {{customerEmail}}</div>
      <!--TODO: Add logic to only show the next 2 data points for High priority reports-->
      <div>
        <div>To: {{reportTo}}</div>
        <div>CC: {{reportCC}}</div>
      </div>
    </div>
    <div class="service-now-report__report-details-container">
      <div class="service-now-report__report-details-header">Contact Instruction</div>
      <div>{{reportContactVia}}</div>
      <!--TODO: Add logic to only add red styling if priority is high-->
      <div class="service-now-report__red-text">{{reportPriority}}</div>
      <div>{{reportContactRequiredTime}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Occurred On</div>
      <div>{{reportOccurredOn}}</div>
      <div class="service-now-report__report-details-header">Contacted On</div>
      <div>{{reportContactedOn}}</div>
      <div class="service-now-report__report-details-header">Report Routed On</div>
      <div *ngFor="let contact of reportRoutedOn">
        <div>{{contact}}</div>
      </div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Customer Contact History</div>
      <div>Prior complaints to any Chick-fil-A: {{customerPriorComplaintsToAnyRestaurant}}</div>
      <!--TODO: Add link to member profile-->
      <div>Prior complaints to this Chick-fil-A:
        <div class="service-now-report__red-text">{{customerPriorComplaintsToThisRestaurant}} - View in Member Profile</div>
      </div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Location Information</div>
      <div>Number: {{locationNumber}}</div>
      <div class="service-now-report__report-details-header">Simple Order/Service Contact</div>
      <div>{{reportOrderServiceContact}}</div>
      <div class="service-now-report__report-details-header">Simple-Service Environment Contact</div>
      <div>{{reportServiceEnvironmentContact}}</div>
    </div>
    <div class="service-now-report__report-history-container-mobile">
      <div class="service-now-report__report-details-header">Active Guidance for Restaurant</div>
      <!--TODO: Add logic to only show red styling if action is overdue-->
      <div class="service-now-report__red-text">{{reportActionGuidance}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Order Placed Via</div>
      <div>{{orderPlacedVia}}</div>
      <div class="service-now-report__report-details-header">Order Received Via</div>
      <div>{{orderReceivedVia}}</div>
      <div class="service-now-report__report-details-header">Receipt?</div>
      <div>{{receiptIncluded}}</div>
      <div class="service-now-report__report-details-header">Order Number</div>
      <div>{{orderNumber}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Customer Comments</div>
      <div>{{customerComments}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Approved Response Sent To Customer</div>
      <div>{{responseSentToCustomer}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Resolution Status</div>
      <div>{{reportResolutionStatus}}</div>
      <div>Reasons CARES unable to resolve: {{reportReasonUnableToResolve}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Care Offer Status</div>
      <div>{{reportOfferStatus}}</div>
    </div>
</mat-dialog-content>
