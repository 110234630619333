import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MediaChange, MediaObserver } from 'ngx-flexible-layout';
import { Effect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import {debounceTime, map, take} from 'rxjs/operators';

import * as layout from '../actions/layout';

@Injectable()
export class LayoutEffects {
  @Effect()
  public updateView$: Observable<Action> = this.media.asObservable().pipe(

    map((size) => new layout.ChangedViewSizeAction(size[0].mqAlias))
  );

  constructor(
    public dialog: MatDialog,
    public media: MediaObserver
  ) { }
}
