import { Directive, OnChanges, ElementRef, Input, Renderer2, OnInit } from '@angular/core';
import { loadingData } from './loadingData';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import * as lottie from 'lottie-web/build/player/lottie_light';
type LottiePlayer = typeof lottie.default;
const lottiePlayer = lottie as any as LottiePlayer;

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[cfaLoading]'
})
export class CfaLoadingDirective implements OnInit, OnChanges {

    @Input() cfaLoading = false;

    private loadingChild;

    private loadingContainerBG;
    private loadingContainer;

    private initialized: boolean;
    private initializedObs: Observable<boolean>;

    private currPointerStyle: string;
    private currUserSelectStyle: string;

    constructor(public el: ElementRef, public renderer: Renderer2) {
        this.loadingContainerBG = this.renderer.createElement('div');
        this.loadingContainer = this.renderer.createElement('div');
    }

    private init(): Observable<boolean> {
        if (this.initialized) {
            return of(this.initialized);
        } else {
            if (!this.initializedObs) {
                this.initializedObs = Observable.create(observer => {
                    this.renderer.appendChild(this.el.nativeElement, this.loadingContainerBG);
                    this.renderer.appendChild(this.loadingContainerBG, this.loadingContainer);

                    this.loadingChild = lottiePlayer.loadAnimation({
                        container: this.loadingContainer,
                        renderer: 'svg',
                        loop: true,
                        autoplay: false,
                        animationData: JSON.parse(loadingData)
                    });
                    this.loadingChild.setSpeed(2);

                    this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');

                    this.renderer.setStyle(this.loadingContainerBG, 'display', 'none');
                    this.renderer.setStyle(this.loadingContainerBG, 'background-color', 'rgba(255,255,255,0.6)');
                    this.renderer.setStyle(this.loadingContainerBG, 'width', '100%');
                    this.renderer.setStyle(this.loadingContainerBG, 'height', '100%');
                    this.renderer.setStyle(this.loadingContainerBG, 'position', 'absolute');
                    this.renderer.setStyle(this.loadingContainerBG, 'right', '0');
                    this.renderer.setStyle(this.loadingContainerBG, 'left', '0');
                    this.renderer.setStyle(this.loadingContainerBG, 'top', '0');
                    this.renderer.setStyle(this.loadingContainerBG, 'bottom', '0');

                    this.renderer.setStyle(this.loadingContainer, 'display', 'none');
                    this.renderer.setStyle(this.loadingContainer, 'margin', 'auto');
                    this.renderer.setStyle(this.loadingContainer, 'max-width', '200px');
                    this.renderer.setStyle(this.loadingContainer, 'width', '100%');
                    this.renderer.setStyle(this.loadingContainer, 'position', 'absolute');
                    this.renderer.setStyle(this.loadingContainer, 'right', '0');
                    this.renderer.setStyle(this.loadingContainer, 'left', '0');
                    this.renderer.setStyle(this.loadingContainer, 'top', '0');
                    this.renderer.setStyle(this.loadingContainer, 'bottom', '0');

                    this.initialized = true;
                    observer.next(this.initialized);
                });
            }
            return this.initializedObs;
        }
    }

    ngOnInit() {
        this.init().pipe(first()).subscribe();
    }

    ngOnChanges() {
        this.init().pipe(first()).subscribe(() => {
            this.processChange();
        });
    }

    private processChange() {
        if (this.cfaLoading) {
            this.currPointerStyle = (this.el.nativeElement as HTMLElement).style.pointerEvents;
            this.currPointerStyle = (this.el.nativeElement as HTMLElement).style.userSelect;
            this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');
            this.renderer.setStyle(this.el.nativeElement, 'user-select', 'none');

            this.renderer.setStyle(this.loadingContainerBG, 'display', 'block');
            this.renderer.setStyle(this.loadingContainer, 'display', 'block');

            this.loadingChild.play();
        } else {
            if (this.loadingChild) {
                this.loadingChild.stop();
                this.renderer.setStyle(this.loadingContainerBG, 'display', 'none');
                this.renderer.setStyle(this.loadingContainer, 'display', 'none');

                if (this.currPointerStyle) {
                    this.renderer.setStyle(this.el.nativeElement, 'pointer-events', this.currPointerStyle);
                } else {
                    this.renderer.removeStyle(this.el.nativeElement, 'pointer-events');
                }

                if (this.currUserSelectStyle) {
                    this.renderer.setStyle(this.el.nativeElement, 'user-select', this.currPointerStyle);
                } else {
                    this.renderer.removeStyle(this.el.nativeElement, 'user-select');
                }
            }
        }
    }
}
