import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedConstants } from '../shared.constants';
import { DiscoverConstants } from '../../discover/discover.constants';
import * as moment from 'moment-timezone';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import * as engage from '../../actions/engage';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';

@Component({
  selector: 'app-bonus-points-promotion',
  templateUrl: './bonus-points-promotion.component.html',
  styleUrls: ['./bonus-points-promotion.component.scss']
})
export class BonusPointsPromotionComponent implements OnInit {
  @Input() areDatesWithinSevenDays;
  @Input() bonusPointId;
  @Input() campaignName;
  @Input() destinations;
  @Input() disabledDays;
  @Input() endDate;
  @Input() endTime;
  @Input() endTimeRestriction;
  @Input() isMobile;
  @Input() isReview;
  @Input() maxDaysAllowed;
  @Input() noteText;
  @Input() readOnlyUser: boolean;
  @Input() repeatPromotionButtons;
  @Input() repeatThisPromotion;
  @Input() selectedDestination;
  @Input() selectedDestinationIdsForMultiSelect;
  @Input() selectedLocation;
  @Input() selectedRepeatPromotion;
  @Input() selectedTimeOfDay;
  @Input() startDate;
  @Input() startTime;
  @Input() startTimeRestriction;
  @Input() stats;
  @Input() statsLoading;
  @Input() suggestedUseText;
  @Input() timeOfDayOptions;
  @Input() title;

  @Output() goBackCalendar = new EventEmitter<any>();
  @Output() goToDiscover = new EventEmitter<any>();
  @Output() goToPreviewPage = new EventEmitter<any>();
  @Output() onAnyEngagementChange = new EventEmitter<any>();
  @Output() onRepeatPromotionSelected = new EventEmitter<any>();
  @Output() onSelectedTimeOfDayChange = new EventEmitter<any>();
  @Output() onSendPromotion = new EventEmitter<any>();
  @Output() onStartTimeChanged = new EventEmitter<any>();
  @Output() onUpdateDate = new EventEmitter<any>();
  @Output() onUpdateMultiSelectDestinations = new EventEmitter();

  public campaignNameFormControl: FormControl;
  public startTimeFormControl = new FormControl();
  public endTimeFormControl = new FormControl();
  public editForm = new FormGroup({});

  public customTimeOfDay = SharedConstants.customTimeOfDay;
  public weeklyRepeatPromotion = SharedConstants.weeklyRepeatPromotion;
  public starIcon = SharedConstants.starIcon;
  public campaignType = DiscoverConstants.bonusPoint;

  public totalCustomersHelp = SharedConstants.BPPTotalCustomersHelp;
  public totalSalesHelp = SharedConstants.BPPTotalSalesHelp;
  public averageCheckHelp = SharedConstants.BPPAverageCheckHelp;
  public totalPointsEstimateHelp = SharedConstants.BPPTotalPointsEstimateHelp;
  public totalNewCustomersHelp = SharedConstants.BPPTotalNewCustomersHelp;

  constructor(private store: Store<fromRoot.State>, public dialog: MatDialog) { }

  public ngOnInit() {
    this.campaignNameFormControl = new FormControl(
      this.campaignName,
      [Validators.pattern('^(?!\\s*$).+'), Validators.required]
    );
    this.addValidationStartAndEndTime(this.selectedTimeOfDay);

    this.editForm.addControl('campaignName', this.campaignNameFormControl);
  }

  public addValidationStartAndEndTime(selectedTimeOfDay) {
    if (selectedTimeOfDay === this.customTimeOfDay) {
      this.startTimeFormControl = new FormControl('', [Validators.required]);
      this.endTimeFormControl = new FormControl('', [Validators.required]);
      this.startTimeFormControl.markAsTouched();
      this.endTimeFormControl.markAsTouched();
      this.editForm.addControl('startTime', this.startTimeFormControl);
      this.editForm.addControl('endTime', this.endTimeFormControl);
    } else {
      this.editForm.removeControl('startTime');
      this.editForm.removeControl('endTime');
    }
  }

  public onCampaignNameChange(evt) {
    this.onAnyEngagementChange.emit({ campaignName: evt.target.value });
  }

  public updateDestination(selectedDestinationId) {
    this.onAnyEngagementChange.emit({ selectedDestinationId });
  }

  public updateMultiSelectDestinations(selectedDestinationIds) {
    this.onUpdateMultiSelectDestinations.emit({ selectedDestinationIds });
  }

  public updateTimeOfDay(timeOfDay) {
    this.addValidationStartAndEndTime(timeOfDay);
    this.onSelectedTimeOfDayChange.emit(timeOfDay);
  }

  public startTimeChanged(startTime) {
    this.onStartTimeChanged.emit(startTime)
  }

  public endTimeChanged(endTime) {
    this.onAnyEngagementChange.emit({ endTime });
  }

  public updateRepeatPromotion(selectedRepeat) {
    this.onRepeatPromotionSelected.emit(selectedRepeat);
  }

  public updateRepeatPromotionDay(selectedDay) {
    this.onAnyEngagementChange.emit({ repeatPromotionSubId: selectedDay });
  }

  public updateDate(dates) {
    this.onUpdateDate.emit(dates);
  }

  public isPromotionPastOrCancelled(): boolean {
    return (this.endDate).isBefore(moment());
  }

  public cancelScheduledPromotion() {
    let config = new MatDialogConfig();
    config.data = {
      displayText: 'Are you sure you want to cancel this promotion?',
      confirmText: 'Yes, Cancel Promotion',
      cancelText: 'No, Keep Promotion'
    };

    this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.store.dispatch(new engage.CancelBonusPointPromotionFromCalendar({ fieldId: this.bonusPointId }));
      }
    });
  }

  public sendPromotion() {
    this.onSendPromotion.emit();
  }
}
