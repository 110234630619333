import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { clone, find, propEq } from 'ramda';
import { FormControl, Validators } from '@angular/forms';
import { SharedConstants } from '../shared.constants';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-third-party-promotion',
  templateUrl: './third-party-promotion.component.html',
  styleUrls: ['./third-party-promotion.component.scss']
})
export class ThirdPartyPromotionComponent implements OnInit {
  @Input() allDaysDisabled: boolean = false;
  @Input() campaignName;
  @Input() customerTypes;
  @Input() disabledDays: number[] = [];
  @Input() enableTermsAndConditions: boolean = false;
  @Input() endDate;
  @Input() endDateRestriction;
  @Input() isMobile;
  @Input() isReview;
  @Input() platforms;
  @Input() rewardCategories;
  @Input() rewardList: [];
  @Input() selectedReward;
  @Input() startDate;
  @Input() stats;
  @Input() statsLoading;
  @Input() thirdPartyId;
  @Input() tile;

  @Output() onGoToCalendar = new EventEmitter();
  @Output() onGoToPreview = new EventEmitter();
  @Output() onSendThirdPartyPromotion = new EventEmitter();
  @Output() onStopThirdPartyPromotion = new EventEmitter();
  @Output() thirdPartyCustomerTypesUpdate = new EventEmitter();
  @Output() thirdPartyDateUpdate = new EventEmitter<any>();
  @Output() thirdPartyPlatformUpdated = new EventEmitter();
  @Output() thirdPartyPromotionUpdated = new EventEmitter();
  @Output() thirdPartyUpdateCampaignName = new EventEmitter<any>();
  @Output() thirdPartyUpdateReward = new EventEmitter<any>();

  public totalTransHelp = SharedConstants.ThirdPartyTotalTransactionsHelp;
  public promotionalTransHelp = SharedConstants.ThirdPartyTotalSalesHelp;
  public totalSalesHelp = SharedConstants.ThirdPartyPromotionTransactionsHelp;
  public promotionalSalesHelp = SharedConstants.ThirdPartyPromotionSalesHelp;
  public promotionalCostHelp = SharedConstants.ThirdPartyFoodAndPaperCostHelp;

  public termsText = 'Terms & Conditions.';
  public submitted = false;
  public campaignNameFormControl = new FormControl();

  constructor() {  }

  ngOnInit(): void {
    this.campaignNameFormControl = new FormControl(
      '',
      [Validators.pattern('^(?!\\s*$).+'), Validators.required]
    );
    this.campaignNameFormControl.patchValue(this.campaignName);
  }

  public updateReward(selectedReward) {
    this.thirdPartyUpdateReward.emit(selectedReward);
  }

  public onCampaignNameChange(event) {
    this.thirdPartyUpdateCampaignName.emit(event);
  }

  promotionTermsAndConditionsUpdated(platform) {
    let index = this.platforms.indexOf(platform);
    this.platforms[index].termsAndConditionsAccepted = !this.platforms[index].termsAndConditionsAccepted;
    this.thirdPartyPromotionUpdated.emit({platforms: this.platforms});
  }

  platformChanged(platform) {
    let index = this.platforms.indexOf(platform);
    this.platforms[index].selected = !this.platforms[index].selected;
    this.thirdPartyPlatformUpdated.emit({platforms: this.platforms});
  }

  customerTypeUpdated(type) {
    let updatedType = clone(type);
    updatedType.selected = !type.selected;
    this.thirdPartyCustomerTypesUpdate.emit(updatedType);
  }

  getNoPlatformSelected() {
    return this.platforms ? find(propEq('selected',true))(this.platforms) === undefined : true;
  }

  getNoCustomerTypeSelected() {
    return this.customerTypes ? find(propEq('selected',true))(this.customerTypes) === undefined : true;
  }

  getTermsAndConditionsError() {
    let error = false;
    this.platforms.forEach(platform => {
      if (platform.selected && !platform.termsAndConditionsAccepted) {
        error = true;
      }
    });
    return error;
  }

  public updateDate(dates) {
    this.thirdPartyDateUpdate.emit(dates);
  }

  public hasPromotionEnded(): boolean {
    return this.endDate.isBefore(moment());
  }

  public disableSubmit() {
    return this.isReview || this.campaignName.trim() === '' || this.getNoPlatformSelected() || this.getNoCustomerTypeSelected()
      || (this.enableTermsAndConditions && this.getTermsAndConditionsError());
  }

  goToPreview(){
    this.submitted = true;
    this.onGoToPreview.emit();
  }

  goToConfirmation(){
    this.submitted = true;
    this.onSendThirdPartyPromotion.emit();
  }

  goToStopPromotion(){
    this.onStopThirdPartyPromotion.emit();
  }

  goToCalendar(){
    this.onGoToCalendar.emit();
  }
}
