import { find, propEq } from 'ramda';

export class SharedConstants {
  public static get toolbarOptions(): any {
    return {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'align': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }]
      ]
    };
  }

  public static get textBoxReplacements(): any[] {
    return [{
      originalText: 'class="ql-align-right"',
      replacementText: 'style="text-align: right;"'
    }, {
      originalText: 'class="ql-align-center"',
      replacementText: 'style="text-align: center;"'
    }, {
      originalText: 'class="ql-align-justify"',
      replacementText: 'style="text-align: justify;"'
    }];
  }

  public static get earliestTimeOption(): string {
    return '12:00 am';
  }

  public static get latestTimeOption(): string {
    return '11:45 pm';
  }

  public static get activityHistoryPageSize(): number {
    return 5;
  }

  public static get disableCustomerNote(): string {
    return 'Disabled Customers are no longer in your Restaurant Spotlight.';
  }

  public static get communityCareCustomerNote(): string {
    return 'Please see the list below for CFA One customers who saw the promotion while ordering digitally. However, only some are in your Spotlight.';
  }

  public static get disableRewardSendFlag(): string {
    return 'disableRewardSending';
  }

  public static get disableDiscoverTabFlag(): string {
    return 'disableDiscoverTab';
  }

  public static get disableMeetTabFlag(): string {
    return 'disableMeetTab';
  }

  public static get disableRecoverTabFlag(): string {
    return 'disableRecoverTab';
  }

  public static get disableEngageTabFlag(): string {
    return 'disableEngageTab';
  }

  public static get disableCalendarTabFlag(): string {
    return 'disableCalendarTab';
  }

  public static get NoteNoResultsMessage(): string {
    return 'No customer notes found.';
  }

  public static get NoteErrorMessage(): string {
    return 'There was an error loading notes. Please try again later.';
  }

  public static get unsavedChangesTitle(): string {
    return 'You have unsaved changes';
  }

  public static get unsavedChangesText(): string {
    return 'Closing this note will discard any changes you\'ve made. Are you sure you want to continue?';
  }

  public static get unsavedChangesIcon(): string {
    return 'https://www.cfacdn.com/img/Spotlight/Icons/UnsavedChanges_Icon.png';
  }

  public static get unsavedChangesConfirmButton(): string {
    return 'Go Back';
  }

  public static get unsavedChangesCancelButton(): string {
    return 'Discard Changes';
  }

  public static get numbersOnlyErrorMessage(): string {
    return 'Only number inputs are allowed';
  }

  public static maxLengthErrorMessage(maxLength): string {
    return 'Maximum number of characters is ' + maxLength;
  }

  public static minLengthErrorMessage(minLength): string {
    return 'Minimum number of characters is ' + minLength;
  }

  public static get filtersDateRangeId(): string {
    return 'date-range';
  }

  public static get filtersOnBetweenId(): string {
    return 'on-between';
  }

  public static get onBetweenDefaultId(): string {
    return 'any';
  }

  public static get onBetweenDefaultValue(): number {
    return 1000;
  }

  public static get onBetweenDefaultTitle(): string {
    return 'Any Days';
  }

  public static get filtersCustomDateSubFilterId(): string {
    return 'custom-date-range';
  }

  public static get searchByCustomerNameMessage(): string {
    return 'Search by Customer Name';
  }

  public static get noCustomersFoundMessage(): string {
    return 'No customers found';
  }

  public static get customerProfilePage(): string {
    return 'customer-profile';
  }

  public static get engagePage(): string {
    return 'engage';
  }

  public static get discoverPage(): string {
    return 'discover';
  }

  public static get customTimeOfDay(): string {
    return 'custom';
  }

  public static get dailyRepeatPromotion(): string {
    return 'daily';
  }

  public static get weeklyRepeatPromotion(): string {
    return 'weekly';
  }

  public static get starIcon(): string {
    return 'https://www.cfacdn.com/img/Spotlight/Icons/star%20icon.svg';
  }

  public static get settingsCategories(): any[] {
    return [{
      id: 'trends',
      name: 'Trends',
      color: 'rgba(221, 0, 51)',
      fadeColor: 'rgba(221, 0, 51, .35)',
      secondaryColor: 'rgba(0, 79, 113)',
      secondaryFadeColor: 'rgba(0, 79, 113, .35)'
    }, {
      id: 'customer',
      name: 'Customer',
      color: 'rgb(36, 158, 107)',
      fadeColor: 'rgb(36, 158, 107, .35)'
    }, {
      id: 'treat',
      name: 'Reward',
      color: 'rgb(62, 177, 200)',
      fadeColor: 'rgb(62, 177, 200, .35)'
    }, {
      id: 'event',
      name: 'Event',
      color: 'rgb(250, 147, 112)',
      fadeColor: 'rgb(250, 147, 112, .35)'
    }, {
      id: 'email',
      name: 'Email',
      color: 'rgb(153, 72, 120)',
      fadeColor: 'rgb(153, 72, 120, .35)'
    }, {
      id: 'catering',
      name: 'Catering',
      color: 'rgb(255, 181, 73)',
      fadeColor: 'rgb(255, 181, 73, .35)'
    }, {
      id: 'care',
      name: 'Recovery',
      color: 'rgba(0, 79, 113)',
      fadeColor: 'rgba(0, 79, 113, .35)',
    }];
  }

  public static categoryColor(id: string): string {
    let category = find(propEq('id', id))(this.settingsCategories);
    return category.color;
  }

  public static categoryFadeColor(id: string): string {
    let category = find(propEq('id', id))(this.settingsCategories);
    return category.fadeColor;
  }

  public static categorySecondaryColor(id: string): string {
    let category = find(propEq('id', id))(this.settingsCategories);
    return category.secondaryColor;
  }

  public static categorySecondaryFadeColor(id: string): string {
    let category = find(propEq('id', id))(this.settingsCategories);
    return category.secondaryFadeColor;
  }

  public static chartOptions(yAxisFormat: string, isMobile: boolean): any {
    let response = {
      elements: {
        point: {
          radius: 1
        },
        line: {
          tension: 0
        }
      },
      tooltips: {
        enabled: true,
        mode: 'nearest',
        callbacks: {
          label: (tooltipItems, data) => {
            let label = data.datasets[tooltipItems.datasetIndex].label || '';
            if (label) {
              label += ': ';
            }
            if (yAxisFormat === 'roundedCurrency') {
              return label += `$${Math.round(tooltipItems.yLabel).toLocaleString()}`;
            }
            if (yAxisFormat === 'currency') {
              return label += `$${tooltipItems.yLabel.toFixed(2)}`;
            }
            return label += tooltipItems.yLabel.toLocaleString();
          }
        }
      },
      legend: {
        onClick: (e) => e.stopPropagation(),
        display: false,
        labels: {
          usePointStyle: true,
          filter: (legendData) => {
            return !!legendData.text;
          }
        }
      },
      title: {
        display: false,
        text: ''
      },
      scales: {
        xAxes: [{
          display: true,
          ticks: {
            autoSkip: true
          },
          scaleLabel: {
            display: false
          },
          barPercentage: 1.0
        }],
        yAxes: [{
          display: true,
          scaleLabel: {
            display: false
          },
          ticks: {
            userCallback: (label) => {
              // when the floored value is the same as the value we have a whole number
              // prevents decimals on the y axis
              if (Math.floor(label) === label) {
                if (yAxisFormat === 'currency' || yAxisFormat === 'roundedCurrency') {
                  return '$' + Math.round(label).toLocaleString();
                }
                return label.toLocaleString();
              }
            }
          }
        }]
      }
    };
    return isMobile ? { ...response, responsive: true, maintainAspectRatio: false } : response;
  }

  public static get noDataText(): string {
    return 'Based on the selected date range, there is no data for your location.' +
      ' A chart will display here when data is available.';
  }

  public static get filterPlaceIndividualOrder(): string {
    return 'placed-individual-order';
  }

  public static get filterSpecifyMembership(): string {
    return 'membership';
  }

  public static get filterOrdering(): string {
    return 'ordering';
  }

  public static get organizationCode(): string {
    return 'organizationCode';
  }

  public static get communityCareCustomerColumns(): any[] {
    return [{
      prop: 'firstName',
      title: 'First Name',
      size: 40,
    }, {
      prop: 'lastName',
      title: 'Last Name',
      size: 40,
    }, {
      prop: 'transactionDate',
      title: 'Dates',
      size: 40
    }];
  }

  public static get disableIndividualRowAttribute(): string {
    return 'nonSpotCustomer';
  }

  public static get heartIcon(): string {
    return 'https://www.cfacdn.com/img/Spotlight/CCE_HeartIcon.svg';
  }

  public static get bugsnagApiKey(): string {
    return '7263d017f883422d16cab11342852648';
  }

  public static get givingStrategyUrl() {
    return 'https://portal.cfahome.com/en/corporate-social-responsibility/corporate-social-responsibility/corporate-social-responsibility-at-chick-fil-a/'
  }

  public static get operatingStandardUrl() {
    return 'https://portal.cfahome.com/en/corporate-resources/operating-standards/fundraising-for-charitable-organizations-operating-standard/';
  }

  public static get CCEConfirmationButton() {
    return 'https://www.cfacdn.com/img/Spotlight/Other/CCE_Yes_Confirm_button.svg';
  }

  public static get CCEGoBackButton() {
    return 'https://www.cfacdn.com/img/Spotlight/Other/CCE_GoBack_button.svg';
  }

  public static get ThirdPartyTotalTransactionsHelp(): string {
    return 'Total number of transactions made on the selected Third Party platform(s) during the promo period.';
  }

  public static get ThirdPartyTotalSalesHelp(): string {
    return 'Transactions made on the selected Third Party Platform(s) during the promo period that included the promotional offer.';
  }

  public static get ThirdPartyPromotionTransactionsHelp(): string {
    return 'Total sales ($) on the selected Third Party Platform(s) during the promo period.';
  }

  public static get ThirdPartyPromotionSalesHelp(): string {
    return 'Sales ($) on the selected Third Party Platform(s) that included the promotional offer.';
  }

  public static get ThirdPartyFoodAndPaperCostHelp(): string {
    return 'Total cost (food & paper) based on total promotional offer redemptions.';
  }

  public static get MultipleCommunityCareEvents():string {
    return 'multipleCommunityCareEvents';
  }

  public static get BPPTotalSalesHelp():string {
    return 'Total sales $ from participating customers in the bonus points promotion.'
  }

  public static get BPPAverageCheckHelp():string {
    return 'Total check average $.'
  }

  public static get BPPTotalCustomersHelp():string {
    return 'Total number of customers that participated in the bonus points promotion.'
  }

  public static get BPPTotalPointsEstimateHelp():string {
    return 'Cost is a rough estimate assuming 100% of points issued in the promotion are used for rewards that were redeemed at your location.'
  }

  public static get BPPTotalNewCustomersHelp():string {
    return '% of Customers that ordered at the selected destination for the first time in the last 180 days.'
  }

  public static get FICAverageCheckHelp():string {
    return 'Average check size for sales completed during the promo period.'
  }

  public static get FICTotalCustomersHelp():string {
    return 'Total number of customers that completed transactions at your restaurant during the promo period.'
  }

  public static get FICTransactionsHelp():string {
    return 'Transactions made during the promo period that included the promotional offer.'
  }

  public static get FICSalesHelp():string {
    return 'Sales total ($) that includes tax and delivery fee (if applicable) but not the cost of the promotional offer.'
  }

  public static get FICCostHelp():string {
    return 'Total cost (food & paper) based on total promotional offer redemptions.'
  }
}
