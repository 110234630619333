import '../styles/style.scss';

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { CfaLoadingModule } from '../app/cfa-controls-module/loading/cfa-loading-module';
import { QuillModule } from 'ngx-quill';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { metaReducers, reducers } from './reducers';

// Modules
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { DiscoverModule } from './discover/discover.module';

// Effects
import { LayoutEffects } from './effects/layout';
import { CustomerEffects } from './effects/customer';
import { MeetEffects } from './effects/meet';
import { CareEffects } from './effects/care';
import { ProfileEffects } from './effects/profile';
import { DiscoverEffects } from './effects/discover';
import { EngagementEffects } from './effects/engagement';
import { SettingsEffects } from './effects/settings';
import { UserEffects } from './effects/user';
import { EulaEffects } from './effects/eula';
import { UnderstandEffects } from './effects/understand';
import { EngageEffects } from './effects/engage';
import { CalendarEffects } from './effects/calendar';
import { NotificationEffects } from './effects/notifications';
import { ActiveRewardsEffects } from './effects/active-rewards';
import { ManageAttendeesEffects } from './effects/manage-attendees';

// Services
import { UserService } from './core/user';
import { EulaService } from './eula/eula.service';

// Components
import { AppComponent } from './app.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';

//okta
import { OktaAuthModule, OktaConfig } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import oktaConfig from './app.config';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { AuthInterceptor } from './shared/okta/auth.interceptor';
import { NavigationService } from "./navigation.service";
import { RoleGuard } from "./roleGuard.service";
const oktaAuth = new OktaAuth(oktaConfig.oidc);
const moduleConfig: OktaConfig = { oktaAuth };

export function setUp(navService: NavigationService, userService: UserService, eulaService: EulaService) {
  return () => {
    return navService.setup().then((res) => {
      if (res){
        return userService.setupUser().then(res =>{
          return eulaService.getEula()
        })
      }
    });
  }
}

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      },
      metaReducers
    }),
    HttpClientModule,
    StoreDevtoolsModule.instrument({ maxAge: 50 }),
    EffectsModule.forRoot([
      CustomerEffects,
      MeetEffects,
      CareEffects,
      LayoutEffects,
      ProfileEffects,
      DiscoverEffects,
      EngagementEffects,
      UnderstandEffects,
      EngageEffects,
      CalendarEffects,
      NotificationEffects,
      ActiveRewardsEffects,
      ManageAttendeesEffects,
      SettingsEffects,
      UserEffects,
      EulaEffects
    ]),
    SharedModule.forRoot(),
    QuillModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    CoreModule,
    CfaLoadingModule,
    DiscoverModule,
    OktaAuthModule.forRoot(moduleConfig)
  ],
  declarations: [
    AppComponent,
    AlertDialogComponent
  ],
  providers: [
    UserService,
    HttpClient,
    HttpClientModule,
    NavigationService,
    RoleGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: setUp, deps: [NavigationService, UserService, EulaService], multi: true },

  ]
})
export class AppModule {}
