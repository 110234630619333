<div class="edit-community-details__form">
  <template-summary [templateName]="tile.descriptionText.summary + ' ' + tile.descriptionText.secondarySubText"
                    [imageURL]="heartIcon"
                    [details]="tile.descriptionText.suggestedUseText"
                    [isMobile]="isMobile"
                    [isDisabled]="true"
                    [isRoundedBorder]="true">
  </template-summary>
  <ng-container>
    <div class="edit-community-details__header">
      Opting In
    </div>
    <div class="edit-community-details__toggle-btns">
      <button-toggle [buttons]="optInButtons"
                     [isFullWidth]="true"
                     [isMobile]="isMobile"
                     [disabled]="isReview"
                     (buttonToggled)="updateOptIn($event)">
      </button-toggle>
    </div>
  </ng-container>
  <ng-container [formGroup]="editForm">
    <div *ngFor="let organization of organizations; let i = index">
      <div>
        <div class="edit-community-details__header"
             [ngClass]="{'edit-community-details__error-missing-field-header':
                         i === 0 ? organizationOneNameFormControl && !organizationOneNameFormControl.valid
                                                                  && organizationOneNameFormControl.touched:
                                   organizationTwoNameFormControl && !organizationTwoNameFormControl.valid
                                                                  && organizationTwoNameFormControl.touched}">
          Organization or Charity Name
        </div>
        <mat-form-field appearance="none" class="edit-community-details__name-input">
          <input matInput
                 maxlength="75"
                 [formControl]="i === 0 ? organizationOneNameFormControl : organizationTwoNameFormControl"
                 [readonly] = "isReview"
                 [value]="organization.organizationName"
                 (blur)="checkDuplicateValidator()"
                 (keyup)="onOrganizationNameInputChange($event, i)">
          <mat-error *ngIf="i === 0 ? organizationOneNameFormControl && organizationOneNameFormControl.errors
                                                                     && organizationOneNameFormControl.errors?.duplicateName:
                                      organizationTwoNameFormControl && organizationTwoNameFormControl.errors
                                                                     && organizationTwoNameFormControl.errors?.duplicateName">
            Organization Names cannot be the same
          </mat-error>
          <mat-error *ngIf="i === 0 ? organizationOneNameFormControl && organizationOneNameFormControl.errors
                                                                     && organizationOneNameFormControl.errors?.required:
                                      organizationTwoNameFormControl && organizationTwoNameFormControl.errors
                                                                     && organizationTwoNameFormControl.errors?.required">
            Organization Name is required
          </mat-error>
        </mat-form-field>
        <div class="edit-community-details__organization-shown-text">
          <small>The organization will appear to customers as shown.</small>
        </div>
        <div *ngIf="selectedOptIn === 'organizationCode'" fxLayout="row wrap" fxLayoutAlign="start space-between">
          <div fxFlex="10"
               fxFlex.sm="18"
               fxFlex.xs="18"
               class="edit-community-details__blue-arrow-section"
               [ngClass]="{'edit-community-details__mobile-blue-arrow-section': isMobile}">
            <img src="https://www.cfacdn.com/img/Spotlight/CCE_Long_Blue_Arrow.svg" alt="">
          </div>
          <div fxFlex="50"
               fxFlex.sm="75"
               fxFlex.xs="75">
            <div class="edit-community-details__header"
                 [ngClass]="{'edit-community-details__error-missing-field-header':
                             i === 0 ? organizationOneCodeFormControl && !organizationOneCodeFormControl.valid
                                                                      && organizationOneCodeFormControl.touched:
                                       organizationTwoCodeFormControl && !organizationTwoCodeFormControl.valid
                                                                      && organizationTwoCodeFormControl.touched}">
              Create an Organization Code
            </div>
            <div class="edit-community-details__sub-header">
              Customers will opt in using the code below. Your code must be 5 to 10 characters in length.
              <b>Please be aware that the code is case-sensitive.</b>
            </div>
            <mat-form-field appearance="none" class="edit-community-details__name-input">
              <input matInput
                     maxlength="10"
                     placeholder="ENTER CODE"
                     [readonly]="isReview"
                     [formControl]="i === 0 ? organizationOneCodeFormControl : organizationTwoCodeFormControl"
                     [value]="organization.organizationCode"
                     (keyup)="onOrganizationCodeInputChange($event, i)">
              <mat-error *ngIf="i === 0 ? organizationOneCodeFormControl && organizationOneCodeFormControl.errors
                                                                         && organizationOneCodeFormControl.errors?.required:
                                          organizationTwoCodeFormControl && organizationTwoCodeFormControl.errors
                                                                         && organizationTwoCodeFormControl.errors?.required">
                Organization Code is required
              </mat-error>
              <mat-error *ngIf="i === 0 ? organizationOneCodeFormControl && organizationOneCodeFormControl.errors
                                                                         && organizationOneCodeFormControl.errors?.minlength:
                                          organizationTwoCodeFormControl && organizationTwoCodeFormControl.errors
                                                                         && organizationTwoCodeFormControl.errors?.minlength">
                Must be at least 5 characters
              </mat-error>
              <mat-error *ngIf="i === 0 ? organizationOneCodeFormControl && organizationOneCodeFormControl.errors
                                                                         && organizationOneCodeFormControl.errors?.pattern:
                                          organizationTwoCodeFormControl && organizationTwoCodeFormControl.errors
                                                                         && organizationTwoCodeFormControl.errors?.pattern">
                No special characters
              </mat-error>
            </mat-form-field>
            <div class="edit-community-details__organization-shown-text">
              <small>Use only numbers and letters</small>
              <span *ngIf="organization.organizationCode" class="edit-community-details__organizationCodeCharCount">
                {{organization.organizationCode.length}}/10
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <button mat-button class="edit-community-details__button"
          *ngIf="organizations.length > 1 && !isReview"
          (click)="removeOrganization()">
    Remove additional organization
  </button>
  <button mat-button class="edit-community-details__button"
          *ngIf="organizations.length === 1 && !isReview"
          (click)="addOrganization()">
    Add an additional organization
  </button>

  <ng-container>
    <div class="edit-community-details__header"
         [ngClass]="{'edit-promotion-details__error-missing-field-header': (campaignNameFormControl && campaignNameFormControl.invalid)}">
      Internal Campaign Name
    </div>
    <mat-form-field appearance="none" class="edit-community-details__name-input"
                    [ngClass]="{'edit-community-details__disabled-name-input': isReview}">
      <input matInput
             maxlength="75"
             [formControl]="campaignNameFormControl"
             [value]="campaignName"
             [readonly]="isReview"
             (keyup)="onCampaignNameChange($event)">
      <mat-error>
        Internal Campaign Name is required
      </mat-error>
    </mat-form-field>
  </ng-container>
  <div>
    <div class="edit-community-details__header">
      Event Dates
    </div>
    <div class="edit-community-details__event-dates-container" fxLayout="column" fxLayoutAlign="start start">
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div fxLayoutAlign="start start">
          <date-picker placeholder="Start Date"
                       [isMobile]="isMobile"
                       [disabled]="isReview"
                       [disabledDays]="disabledDays"
                       [date]="dateAndTimeObject.startDate"
                       [disableSundays]="!isReview"
                       [futureDatesOnly]="!isReview"
                       (dateChanged)="startDateChanged($event)">
          </date-picker>
          <time-picker class="edit-community-details__time-picker"
                       [ngClass]="{'edit-community-details__disabled-time-picker': isReview}"
                       placeholder="Start Time"
                       [time]="dateAndTimeObject.startTime"
                       [startTimeRestriction]="dateAndTimeObject.startTimeRestriction"
                       [validationControl]="startTimeFormControl"
                       [disabled]="isReview"
                       [disabledTimes]="disabledTimes ? disabledTimes.start : []"
                       (timeSelected)="onStartTimeChanged.emit($event)">
          </time-picker>
        </div>
        <span class="edit-community-details__time-zone">{{(selectedLocation).locationTimeZoneForDisplay}}</span>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div fxLayoutAlign="start start">
          <date-picker placeholder="End Date"
                       [isMobile]="isMobile"
                       [date]="dateAndTimeObject.endDate"
                       [minDate]="dateAndTimeObject.startDate"
                       [maxDate]="dateAndTimeObject.endDateRestriction"
                       [disabled]="isReview"
                       [disabledDays]="disabledDays"
                       [disableSundays]="!isReview"
                       [futureDatesOnly]="!isReview"
                       (dateChanged)="endDateChanged($event)">
          </date-picker>
          <time-picker class="edit-community-details__time-picker"
                       placeholder="End Time"
                       [ngClass]="{'edit-community-details__disabled-time-picker': isReview}"
                       [time]="dateAndTimeObject.endTime"
                       [startTimeRestriction]="dateAndTimeObject.endTimeRestriction"
                       [validationControl]="endTimeFormControl"
                       [disabled]="isReview"
                       [disabledTimes]="disabledTimes ? disabledTimes.end : []"
                       (timeSelected)="endTimeChanged($event)">
          </time-picker>
        </div>
        <span class="edit-community-details__time-zone">{{(selectedLocation).locationTimeZoneForDisplay}}</span>
      </div>
      <mat-nav-list *ngIf="!isReview" class="edit-community-details__list">
        <overlapping-engagement-summary  (onSummaryClick)="onGoToOverlappingEvents.emit()">
        </overlapping-engagement-summary>
      </mat-nav-list>
    </div>
  </div>

  <ng-container *ngIf="isReview">
    <mat-card *ngIf="showCustomers"
              [ngClass]="isMobile ? 'edit-community-details__mobile-customer-container' : 'edit-community-details__customer-container'">
      <mat-form-field appearance="outline" class="edit-community-details__search input-search-wrapper">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput
               type="search"
               placeholder="{{searchByCustomerNameMessage}}"
               [(ngModel)]="recipientsSearchTerm"
               (keyup)="searchRecipients(false)">
        <mat-icon matSuffix *ngIf="recipientsSearchTerm" class="edit-community-details__search-close" (click)="searchRecipients(true)">
          close
        </mat-icon>
      </mat-form-field>
      <div class="edit-community-details__sales-text">
        Total Sales for Community Care Event: ${{totalCommunityCareEventSales}}
      </div>
      <div class="edit-community-details__note-text">
        {{communityCareCustomerNote}}
      </div>
      <div *ngFor="let organization of organizations; let i = index;">
        <collapsible-card *ngIf="organization.totalSales" fxFlex="100" title="{{getOrganizationTitle(organization, organizations)}}">
          <data-table [totalElements]="filteredRecipients[i].length"
                      [frontEndPaging]="true"
                      [columns]="tableColumns"
                      [pageSize]="isMobile ? 10 : 20"
                      [rows]="filteredRecipients[i]"
                      noResultsMessage="{{noCustomersFoundMessage}}"
                      [disableIndividualRowAttribute]="getEngageDisableIndividualRowAttribute"
                      [numberOfPages]="getNumberOfPages(filteredRecipients[i].length)"
                      [allCustomerIds]="filteredRecipients[i]"
                      [useSmallRowSize]="true"
                      [isMobileWithDesktopView]="isMobile">
          </data-table>
        </collapsible-card>
      </div>
    </mat-card>
    <mat-card *ngIf="!showCustomers"
              [ngClass]="isMobile ? 'edit-community-details__mobile-customer-container' : 'edit-community-details__customer-container'">
      <mat-icon class="edit-community-details__card-image">check_circle_outline</mat-icon>
      <div fxLayout="row wrap">
        <h4 class="edit-community-details__card-header ">You are all set! </h4>
        <span class="edit-community-details__card-section">
          Updating stats may take a little longer. Please wait 24 to 48 hours to access the stats for this Community Care Event.
        </span>
      </div>
    </mat-card>
  </ng-container>
  <div class="edit-community-details__reference-note-container">
    <span class="edit-community-details__note-title">Note: </span>
    <span class="edit-community-details__note-text">Please reference the
      <a href="{{operatingStandardUrl}}"
         target="_balnk" class="edit-community-details__hyperlink">Fundraising for Charitable Organizations Operating Standard</a>.
      <span class="edit-community-details__read-more-button" *ngIf="!readMoreExpanded" (click)="expandReadMore()">Read more</span>
    </span>
    <div *ngIf="readMoreExpanded">
      <br>
      Community Care Events are commercial co-venture (CCV) promotions. The promotion must be compliant with the state statutes applicable
      to CCV promotions in the state in which the promotion is offered. Each market should have their local counsel review these promotions.
      Each market must also determine any advertising disclosures that may be required.
      <br><br>
      It is the Operator's responsibility to have the applicable agreements in place with the charitable organization, to remit the
      applicable amounts, and to comply with any state filing requirements. The Operator is subject to the audit rights of the charitable
      organization.
      <span class="edit-community-details__read-more-button" (click)="collapseReadMore()">Read less</span>
    </div>
  </div>
  <div *ngIf="!(isMobile) && !(isReview)"
       fxLayout="row"
       fxLayoutAlign="end center"
       class="edit-community-details__button-container">
    <button mat-raised-button
            class="edit-community-details__send-button"
            fxFlex="45"
            fxFlexAlign="end"
            [disabled]="editForm.invalid"
            (click)="validateAndSend(false)">
      Complete Set Up
    </button>
  </div>
  <div *ngIf="(isReview)"
       fxLayout="row"
       fxLayoutAlign="end center"
       class="edit-community-details__button-container">
    <button mat-raised-button
            class="edit-community-details__send-button"
            fxFlex="45"
            fxFlexAlign="start"
            (click)="onGoBack.emit()">
      Back to Calendar
    </button>
    <button mat-raised-button
            class="edit-community-details__cancel-button"
            fxFlex="45"
            fxFlexAlign="end"
            [disabled]="isEventPastOrCancelled()"
            (click)="cancelScheduledEvent()">
      Stop Event
    </button>
  </div>
  <div *ngIf="isMobile && !isReview" class="edit-community-details__mobile-footer" fxLayout="row" fxLayoutAlign="space-between">
    <button mat-raised-button
            class="edit-community-details__mobile-action-button"
            fxFlex="45"
            (click)="onGoBack.emit()">
      Back
    </button>
    <button mat-raised-button
            class="edit-community-details__mobile-send-button"
            fxFlex="45"
            fxFlexAlign="end"
            [disabled]="editForm.invalid"
            (click)="validateAndSend(true)">
      Preview
    </button>
  </div>
</div>

