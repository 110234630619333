import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedConstants } from '../shared.constants';
import { EngageConstants } from '../../engage/engage.constants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Store } from '@ngrx/store';
import * as engage from '../../actions/engage';
import * as fromRoot from '../../reducers';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-community-care-details',
  templateUrl: './community-care-details.component.html',
  styleUrls: ['./community-care-details.component.scss']
})
export class CommunityCareDetailsComponent implements OnInit {
  @Input() campaignName;
  @Input() dateAndTimeObject;
  @Input() disabledDays;
  @Input() disabledTimes;
  @Input() eventId;
  @Input() filteredRecipients;
  @Input() isMobile;
  @Input() isReview;
  @Input() optInButtons;
  @Input() organizations: any[];
  @Input() selectedLocation;
  @Input() selectedOptIn;
  @Input() showCustomers;
  @Input() status;
  @Input() tile;
  @Input() totalCommunityCareEventSales;

  @Output() onAddOrganization = new EventEmitter<any>();
  @Output() onAnyDateChange = new EventEmitter<any>();
  @Output() onAnyEngagementChange = new EventEmitter<any>();
  @Output() onGoBack = new EventEmitter();
  @Output() onGoToOverlappingEvents = new EventEmitter<any>();
  @Output() onGoToPreview = new EventEmitter<any>();
  @Output() onOrganizationCodeChange = new EventEmitter<any>();
  @Output() onOrganizationNameChange = new EventEmitter<any>();
  @Output() onRemoveOrganization = new EventEmitter<any>();
  @Output() onSearchRecipients = new EventEmitter<string>();
  @Output() onSendEvent = new EventEmitter<any>();
  @Output() onStartTimeChanged = new EventEmitter<any>();
  @Output() onUpdateOptIn = new EventEmitter<any>();

  public campaignNameFormControl: FormControl;
  public communityCareCustomerNote = SharedConstants.communityCareCustomerNote;
  public editForm: FormGroup;
  public endTimeFormControl: FormControl;
  public getEngageDisableIndividualRowAttribute = SharedConstants.disableIndividualRowAttribute;
  public heartIcon = SharedConstants.heartIcon;
  public noCustomersFoundMessage = SharedConstants.noCustomersFoundMessage;
  public operatingStandardUrl = SharedConstants.operatingStandardUrl;
  public organizationOneCodeFormControl: FormControl;
  public organizationOneNameFormControl: FormControl;
  public organizationTwoCodeFormControl: FormControl;
  public organizationTwoNameFormControl: FormControl;
  public readMoreExpanded: boolean = false;
  public recipientsSearchTerm = '';
  public searchByCustomerNameMessage = SharedConstants.searchByCustomerNameMessage;
  public startTimeFormControl: FormControl;
  public tableColumns = SharedConstants.communityCareCustomerColumns;

  constructor(private fb: FormBuilder, private store: Store<fromRoot.State>, public dialog: MatDialog) {
    this.editForm = this.fb.group({ });
  }

  ngOnInit(): void {
    this.setDateTimeValidation();
    this.campaignNameFormControl = new FormControl(
      this.campaignName,
      [Validators.pattern('^(?!\\s*$).+'), Validators.required]
    );
    this.editForm.addControl('campaignName', this.campaignNameFormControl);
    this.organizationOneNameFormControl = new FormControl(
      this.organizations[0].organizationName,
      [Validators.required]
    );
    this.editForm.addControl('organizationName1', this.organizationOneNameFormControl);
  }

  public setDateTimeValidation() {
    this.startTimeFormControl = new FormControl(this.dateAndTimeObject.startTime, [Validators.required]);
    this.editForm.addControl('startTime', this.startTimeFormControl);
    this.endTimeFormControl = new FormControl(this.dateAndTimeObject.endTime, [Validators.required]);
    this.editForm.addControl('endTime', this.endTimeFormControl);
  }

  public addOrganization() {
    this.onAddOrganization.emit();
    this.organizationTwoNameFormControl = new FormControl(
      this.organizations[1].organizationName,
      [Validators.required]
    );
    this.editForm.addControl('organizationName2', this.organizationTwoNameFormControl);
    if (this.selectedOptIn === 'organizationCode') {
      this.organizationTwoCodeFormControl = new FormControl(
        this.organizations[1].organizationCode,
        [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'), Validators.minLength(5)]
      );
      this.editForm.addControl('organizationCode2', this.organizationTwoCodeFormControl);
    }
  }

  public checkDuplicateValidator() {
    if (this.organizations.length > 1 && this.organizations[0].organizationName === this.organizations[1].organizationName) {
      this.organizationOneNameFormControl.markAsTouched();
      this.organizationOneNameFormControl.setValidators(() => <any>{ duplicateName: true });
      this.organizationOneNameFormControl.updateValueAndValidity();
      this.organizationTwoNameFormControl.markAsTouched();
      this.organizationTwoNameFormControl.setValidators(() => <any>{ duplicateName: true });
      this.organizationTwoNameFormControl.updateValueAndValidity();
    } else if (this.organizations.length > 1) {
      this.organizationOneNameFormControl.clearValidators();
      this.organizationOneNameFormControl.setValidators([Validators.required]);
      this.organizationOneNameFormControl.updateValueAndValidity();
      this.organizationTwoNameFormControl.clearValidators();
      this.organizationTwoNameFormControl.setValidators([Validators.required]);
      this.organizationTwoNameFormControl.updateValueAndValidity();
    }
  }

  public removeOrganization() {
    this.onRemoveOrganization.emit();
    this.editForm.removeControl('organizationName2');
    this.editForm.removeControl('organizationCode2');
  }

  public onOrganizationCodeInputChange(event, index) {
    this.onOrganizationCodeChange.emit({ index, organizationCode: event.target.value });
  }

  public onOrganizationNameInputChange(event, index) {
    this.onOrganizationNameChange.emit({  index, organizationName: event.target.value });
  }

  public onCampaignNameChange(evt) {
    this.onAnyEngagementChange.emit({ campaignName: evt.target.value })
  }

  public updateOptIn(event) {
    // If the code requirement is removed then remove the form validation for it, otherwise add it
    if (event === 'noCode') {
      this.editForm.removeControl('organizationCode1');
      this.editForm.removeControl('organizationCode2');
    } else {
      this.organizationOneCodeFormControl = new FormControl(
        this.organizations[0].organizationCode,
        [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'), Validators.minLength(5)]
      );
      this.editForm.addControl('organizationCode1', this.organizationOneCodeFormControl);

      if (this.organizations.length > 1) {
        this.organizationTwoCodeFormControl = new FormControl(
          this.organizations[1].organizationCode,
          [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'), Validators.minLength(5)]
        );
        this.editForm.addControl('organizationCode2', this.organizationTwoCodeFormControl);
      }
    }
    this.onUpdateOptIn.emit({ selectedOptIn: event });
  }

  public endDateChanged(endDate) {
    this.onAnyDateChange.emit( { startDate: this.dateAndTimeObject.startDate, endDate: endDate });
  }

  public startDateChanged(startDate) {
    this.onAnyDateChange.emit( { startDate: startDate, endDate: this.dateAndTimeObject.endDate });
  }

  public endTimeChanged(endTime) {
    this.onAnyEngagementChange.emit({ endTime: endTime });
  }

  // Only display the total sales if there are more than 1 organization
  public getOrganizationTitle(organization, organizations): string {
    let title = organization.organizationName;
    if (organizations.length > 1) {
      title += ': $' + organization.totalSales;
    }
    return title;
  }

  public getNumberOfPages(totalElements: number): number {
    return Math.ceil(totalElements / 5);
  }

  public searchRecipients(isCleared: boolean) {
    if (isCleared) {
      this.recipientsSearchTerm = '';
    }
    this.onSearchRecipients.emit(this.recipientsSearchTerm);
  }

  public validateAndSend(isPreview: boolean) {
    this.editForm.markAllAsTouched();
    if (this.editForm.valid) {
      if (isPreview) {
        this.onGoToPreview.emit();
      } else {
        this.onSendEvent.emit();
      }
    }
  }

  public expandReadMore() {
    this.readMoreExpanded = true;
  }

  public collapseReadMore() {
    this.readMoreExpanded = false;
  }

  public isEventPastOrCancelled(): boolean {
    return (this.dateAndTimeObject.endDate).isBefore(moment()) || this.status === EngageConstants.cancelledStatus;
  }

  public cancelScheduledEvent() {
    let config = new MatDialogConfig();
    config.data = {
      displayText: 'Are you sure you want to cancel this event?',
      confirmText: 'Yes, Cancel Event',
      cancelText: 'No, Keep Event'
    };

    this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.store.dispatch(new engage.CancelCommunityCareEventFromCalendar({ eventId: this.eventId }));
      }
    });
  }
}
