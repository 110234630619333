import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CfaLoadingIndicator } from './cfa-loading-indicator';
import { CfaLoadingDirective } from './cfa-loading-directive';
import { CfaLoadingService } from './cfa-loading-service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        CfaLoadingIndicator,
        CfaLoadingDirective
    ],
    exports: [
        CfaLoadingIndicator,
        CfaLoadingDirective
    ],
    providers: [
        CfaLoadingService
    ]
})
export class CfaLoadingModule {}
