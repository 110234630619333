import { Component, ElementRef, ViewChild, Input, AfterViewInit, OnChanges, Renderer2 } from '@angular/core';
import { fromEvent } from 'rxjs';
import { loadingData } from './loadingData';
import { CfaLoadingService } from './cfa-loading-service';
import * as lottie from 'lottie-web/build/player/lottie_light';
type LottiePlayer = typeof lottie.default;
const lottiePlayer = lottie as any as LottiePlayer;

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'cfa-loading-indicator',
    exportAs: 'CfaLoadingIndicator',
    templateUrl: './cfa-loading-indicator.html',
    styleUrls: [ './cfa-loading-indicator.css']
})
// tslint:disable-next-line:component-class-suffix
export class CfaLoadingIndicator implements AfterViewInit, OnChanges {

    @Input() doNotWaitForLoopToEnd = false;
    @Input() preventClicks = false;
    @Input() bgColor = 'transparent';

    loadingAnim: any;
    loading = false;
    hideLoading = true;

    @ViewChild('loadingAnimation') loadingAnimation: ElementRef;
    @ViewChild('loadingIndicator', { static: true }) loadingIndicator: ElementRef;

    constructor(private loadingService: CfaLoadingService,
        private renderer: Renderer2) { }

    ngAfterViewInit() {
        this.loadingAnim = lottiePlayer.loadAnimation({
            container: this.loadingAnimation.nativeElement,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: JSON.parse(loadingData)
        });
        this.loadingAnim.setSpeed(2);

        this.loadingService.showLoadingChanged().subscribe(loading => {
            this.loading = loading;
            if (loading) {
                this.hideLoading = false;
                this.loadingAnim.play();
            } else {
                if (this.doNotWaitForLoopToEnd) {
                    this.loadingAnim.stop();
                    this.hideLoading = true;
                }
            }
        });

        if (!this.doNotWaitForLoopToEnd) {
            fromEvent(this.loadingAnim, 'loopComplete')
                .subscribe(() => {
                    if (!this.loading) {
                        this.loadingAnim.stop();
                        this.hideLoading = true;
                    }
                });
        }
        this.updateLoadingIndicator();
    }

    ngOnChanges() {
        this.updateLoadingIndicator();
    }

    updateLoadingIndicator() {
        this.renderer.setStyle(this.loadingIndicator.nativeElement, 'background-color', this.bgColor);
        if (this.preventClicks) {
            this.renderer.setStyle(this.loadingIndicator.nativeElement, 'pointer-events', 'all');
            this.renderer.setStyle(this.loadingIndicator.nativeElement, 'user-select', 'none');
        } else {
            this.renderer.setStyle(this.loadingIndicator.nativeElement, 'pointer-events', 'none');
            this.renderer.removeStyle(this.loadingIndicator.nativeElement, 'user-select');
        }
    }
}
