export class NavMenuConstants {
  public static get navMenuOptions(): any[] {
    return [{
      isLocationMenu: true,
      options: [{
        id: 'location',
        imageURL: '../assets/img/location_marker.png',
        label: 'Location',
        showChangeBtn: true
      }],
      title: 'Change your restaurant'
    }, {
      options: [{
        id: 'calendar',
        imageURL: '../assets/img/calendar-menu.png',
        label: 'Calendar'
      }],
      title: 'More Options'
    }, {
      options: [{
        id: 'settings',
        imageURL: '../assets/img/settings-menu.png',
        label: 'Settings'
      }, {
        id: 'notifications',
        imageURL: '../assets/img/notifications-menu.png',
        label: 'Notifications'
      }, {
        id: 'help',
        imageURL: '../assets/img/help-menu.png',
        label: 'Help'
      }, {
        id: 'give-feedback',
        imageURL: '../assets/img/give-feedback-menu.png',
        label: 'Give feedback'
      }],
      title: 'Account'
    }, {
      options: [{
        id: 'logout',
        imageURL: '../assets/img/logout-menu.png',
        label: 'Log out'
      }],
      title: 'Leave Spotlight'
    }];
  }

  public static get navMenuOptionsWithoutCalendar(): any[] {
    return [{
      isLocationMenu: true,
      options: [{
        id: 'location',
        imageURL: '../assets/img/location_marker.png',
        label: 'Location',
        showChangeBtn: true
      }],
      title: 'Change your restaurant'
    }, {
      options: [{
        id: 'settings',
        imageURL: '../assets/img/settings-menu.png',
        label: 'Settings'
      }, {
        id: 'notifications',
        imageURL: '../assets/img/notifications-menu.png',
        label: 'Notifications'
      }, {
        id: 'help',
        imageURL: '../assets/img/help-menu.png',
        label: 'Help'
      }, {
        id: 'give-feedback',
        imageURL: '../assets/img/give-feedback-menu.png',
        label: 'Give feedback'
      }],
      title: 'Account'
    }, {
      options: [{
        id: 'logout',
        imageURL: '../assets/img/logout-menu.png',
        label: 'Log out'
      }],
      title: 'Leave Spotlight'
    }];
  }

  public static get locationSearchTitle(): any {
    return 'Search for a restaurant';
  }
}
