<div class="edit-promotion-details__form">
  <template-summary [templateName]="title"
                    [imageURL]="starIcon"
                    [details]="suggestedUseText"
                    [isMobile]="isMobile"
                    [isDisabled]="true"
                    [isRoundedBorder]="true">
  </template-summary>
  <div class="edit-promotion-details__note-text">
    <span class="edit-promotion-details__note-title">Note: </span>
    <span>{{noteText}}</span>
  </div>

  <ng-container>
    <div class="edit-promotion-details__header"
         [ngClass]="{'edit-promotion-details__error-missing-field-header': (campaignNameFormControl && campaignNameFormControl.invalid)}">
      Internal Campaign Name
    </div>
    <mat-form-field appearance="none" class="edit-promotion-details__name-input">
      <input matInput
             maxlength="75"
             [readonly]="isReview"
             [formControl]="campaignNameFormControl"
             [value]="campaignName"
             (keyup)="onCampaignNameChange($event)">
      <mat-error>
        Internal Campaign Name is required
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container>
    <div class="edit-promotion-details__header">
      Promotion Criteria
    </div>
    <dropdown-by-id id="bpp-destination"
                    [categories]="destinations"
                    [disabled]="isReview"
                    [dropdownLabel]="'Destination'"
                    [enableMultiSelect]="true"
                    [isMobile]="isMobile"
                    [selectedCategoryId]="selectedDestination"
                    [selectedCategoryIdsForMultiSelect]="selectedDestinationIdsForMultiSelect"
                    (onUpdateCategory)="updateDestination($event)"
                    (onUpdateMultiSelectCategories)="updateMultiSelectDestinations($event)"
                    (onUpdateCategoryMobile)="updateDestination($event)">
    </dropdown-by-id>

    <dropdown-by-id id="bpp-time-of-day"
                    [categories]="timeOfDayOptions"
                    [disabled] = "isReview"
                    [dropdownLabel]="'Time of Day'"
                    [isMobile]="isMobile"
                    [removeTopBorder]="true"
                    [selectedCategoryId]="selectedTimeOfDay"
                    (onUpdateCategory)="updateTimeOfDay($event)"
                    (onUpdateCategoryMobile)="updateTimeOfDay($event)">
    </dropdown-by-id>
  </ng-container>
  <div *ngIf="(selectedTimeOfDay) === customTimeOfDay"
       class="edit-promotion-details__time-option-container"
       fxLayout="row wrap"
       fxLayoutAlign="space-between center">
    <div fxFlex="40" fxFlex.gt-sm="30">
      <time-picker class="edit-promotion-details__time-picker"
                   placeholder="Start Time"
                   [disabled] = "isReview"
                   [time]="startTime"
                   [validationControl]="startTimeFormControl"
                   [startTimeRestriction]="startTimeRestriction"
                   (timeSelected)="startTimeChanged($event)">
      </time-picker>
      <span class="edit-promotion-details__time-zone">{{(selectedLocation).locationTimeZoneForDisplay}}</span>
    </div>
    <div fxFlex>
      <time-picker class="edit-promotion-details__time-picker"
                   placeholder="End Time"
                   [disabled]="isReview"
                   [time]="endTime"
                   [validationControl]="endTimeFormControl"
                   [startTimeRestriction]="endTimeRestriction"
                   (timeSelected)="endTimeChanged($event)">
      </time-picker>
      <span class="edit-promotion-details__time-zone">{{(selectedLocation).locationTimeZoneForDisplay}}</span>
    </div>
  </div>
  <div class="edit-promotion-details__date-header">I would like this promotion to</div>
  <ng-container>
    <div class="edit-promotion-details__toggle-btns">
      <button-toggle [disabled]="areDatesWithinSevenDays || isReview"
                     [buttons]="(repeatThisPromotion)"
                     [isFullWidth]="true"
                     [isMobile]="isMobile"
                     (buttonToggled)="updateRepeatPromotion($event)">
      </button-toggle>
    </div>
  </ng-container>
  <ng-container *ngIf="(selectedRepeatPromotion) === weeklyRepeatPromotion">
    <div class="edit-promotion-details__sub-header">
      On every
    </div>
    <div class="edit-promotion-details__toggle-btns">
      <button-toggle [disabled]="areDatesWithinSevenDays || isReview"
                     [buttons]="(repeatPromotionButtons)"
                     [isFullWidth]="true"
                     [isMobile]="isMobile"
                     (buttonToggled)="updateRepeatPromotionDay($event)">
      </button-toggle>
    </div>
  </ng-container>
  <br>
  <date-range-picker [isMobile]="isMobile"
                     [startDate]="startDate"
                     [endDate]="endDate"
                     [disabledDays]="disabledDays"
                     [maxDaysAllowed]="maxDaysAllowed"
                     [startDatePlaceholder]="'Start on'"
                     [endDatePlaceholder]="'End on'"
                     [futureDatesOnly]="true"
                     [disableSundays]="true"
                     [isStartDateRestriction]="true"
                     [isMaxDayRestrictionDynamic]= "false"
                     [disabled]="isReview"
                     [campaignType]="campaignType"
                     (datesChanged)="updateDate($event)">
  </date-range-picker>
  <br>

  <ng-container *ngIf="isReview && !statsLoading && stats">
    <mat-nav-list [ngClass]="isMobile ? 'edit-promotion-details__list-mobile' : 'edit-promotion-details__list'">
      <h4 class="edit-promotion-details__analytics-title">
        Analytics Summary
      </h4>
      <div class="edit-promotion-details__analytics-disclaimer">
        Sales data is generally accurate and may be used for directional insights.
        Analytics Hub may be referenced for more exact sales numbers.
      </div>
      <div class="edit-promotion-details__grid">
        <div fxLayout="row" class="edit-promotion-details__analytics-label">
          Total Number of Customers
          <help-text class="edit-promotion-details__help-text" [helpText]="totalCustomersHelp" [is3pSize]="true"></help-text>:
        </div>
        <div class="edit-promotion-details__analytics-data">{{stats.totalCustomers}}</div>
        <div fxLayout="row" class="edit-promotion-details__analytics-label">
          Estimated Cost of Points
          <help-text class="edit-promotion-details__help-text" [helpText]="totalPointsEstimateHelp" [displayOnLeft]="true" [is3pSize]="true"></help-text>:
        </div>
        <div class="edit-promotion-details__analytics-data">${{stats.estimatedCostOfPoints | number : '1.2-2'}}</div>
        <div fxLayout="row" class="edit-promotion-details__analytics-label">
          Total Sales
          <help-text class="edit-promotion-details__help-text" [helpText]="totalSalesHelp" [is3pSize]="true"></help-text>:
        </div>
        <div class="edit-promotion-details__analytics-data">${{stats.totalSales | number : '1.2-2'}}</div>
        <div fxLayout="row" class="edit-promotion-details__analytics-label">
          Customer Acquisition
          <help-text class="edit-promotion-details__help-text" [helpText]="totalNewCustomersHelp" [displayOnLeft]="true" [is3pSize]="true"></help-text>:
        </div>
        <div class="edit-promotion-details__analytics-data">{{stats.newAcquisitionCustomerPercentage | number : '1.2-2'}}%</div>
        <div fxLayout="row" class="edit-promotion-details__analytics-label">
          Check Average
          <help-text class="edit-promotion-details__help-text" [helpText]="averageCheckHelp" [is3pSize]="true"></help-text>:
        </div>
        <div class="edit-promotion-details__analytics-data">${{stats.salesAverage | number : '1.2-2'}}</div>
        <div></div><div></div>
      </div>
    </mat-nav-list>
  </ng-container>
  <ng-container *ngIf="isReview && !statsLoading && !stats">
    <div class ="edit-promotion-details__stats-error">
      <mat-icon class="edit-promotion-details__report-icon">report</mat-icon>
      <p class="edit-promotion-details__stats-error-text"> We’re having issues loading the analytics summary. Try again later.</p>
    </div>
  </ng-container>

  <div class="edit-promotion-details__sub-note-text">
    Please visit Engage page and use “Bonus Points Promotion” to promote this template. Once this promotion has been created, it can’t be edited from the dashboard.
  </div>

  <div *ngIf="!(isMobile)"
       fxLayout="row"
       fxLayoutAlign="end center"
       class="edit-promotion-details__button-container">
    <button mat-raised-button
            class="edit-promotion-details__send-button"
            fxFlex="45"
            fxFlexAlign="end"
            *ngIf = "!isReview"
            [ngClass]="{'edit-promotion-details__send-button-disabled': editForm.invalid}"
            [disabled]="(editForm.invalid || readOnlyUser || !(selectedDestinationIdsForMultiSelect.length > 0))"
            (click)="sendPromotion()">
      Create Promotion
    </button>
  </div>

  <div *ngIf="isMobile && !isReview" class="edit-promotion-details__mobile-footer" fxLayout="row" fxLayoutAlign="space-between">
    <button mat-raised-button
            class="edit-promotion-details__mobile-action-button"
            fxFlex="45"
            (click)="goToDiscover.emit()">
      Back
    </button>
    <button mat-raised-button
            class="edit-promotion-details__mobile-send-button"
            fxFlex="45"
            fxFlexAlign="end"
            [ngClass]="{'edit-promotion-details__send-button-disabled': editForm.invalid}"
            [disabled]="editForm.invalid || readOnlyUser || !(selectedDestinationIdsForMultiSelect.length > 0)"
            (click)="goToPreviewPage.emit()">
      Preview
    </button>
  </div>
  <div class="edit-promotion-details__button-container" *ngIf="isReview && !(isMobile)" fxLayout="row" fxLayoutAlign="space-between">
    <button mat-raised-button
            class="edit-promotion-details__send-button"
            fxFlex="45"
            fxFlexAlign="end"
            (click)="goBackCalendar.emit()">
      Back to Calendar
    </button>
    <button mat-raised-button
            class="edit-promotion-details__cancel-button"
            fxFlex="45"
            fxFlexAlign="end"
            [disabled]="isPromotionPastOrCancelled()"
            (click)="cancelScheduledPromotion()">
      Stop Promotion
    </button>
  </div>
  <div class="edit-promotion-details__bonus-points-mobile-footer" *ngIf="isReview && isMobile" fxLayout="row" fxLayoutAlign="space-between">
    <button mat-raised-button
            class="edit-promotion-details__mobile-send-button"
            fxFlex="45"
            fxFlexAlign="center"
            (click)="goBackCalendar.emit()">
      Back to Calendar
    </button>
    <button mat-raised-button
            class="edit-promotion-details__cancel-button-mobile"
            fxFlex="45"
            fxFlexAlign="center"
            [disabled]="isPromotionPastOrCancelled()"
            (click)="cancelScheduledPromotion()">
      Stop Promotion
    </button>
  </div>
</div>
