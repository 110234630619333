import { Component, Inject, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { Observable } from 'rxjs';
import { AnalyticsService } from 'spotlight-ui/app/core/analytics';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'service-now-report',
  templateUrl: './service-now-report.component.html',
  styleUrls: ['./service-now-report.component.scss']
})
export class ServiceNowReportComponent {
  public isMobile$: Observable<boolean>;
  public reportNumber: string = '00000000';
  public customerName: string = 'First Last';
  public customerPhoneNumber: string = '(XXX) XXX-XXXX';
  public customerEmail: string = '(XXX) XXX-XXXX';
  public reportContactVia: string = 'Contact via <Web Entry/Phone Call>';
  public reportPriority: string = 'Priority High';
  public reportContactRequiredTime: string = 'Contact required within 48 hours';
  public reportOccurredOn: string = 'Mon, April 06, 2024 10:46 PM EST';
  public reportContactedOn: string = 'Mon, April 06, 2024 10:41 PM EST';
  public reportRoutedOn: string[] = ['1st Contact: Mon, April 06, 2024 02:30 PM EST', '2nd Contact: Mon, April 06, 2024 10:30 PM EST',
                                     '3rd Contact: Mon, April 06, 2024 10:41 PM EST'];
  public customerPriorComplaintsToAnyRestaurant: number = 2;
  public customerPriorComplaintsToThisRestaurant: number = 1;
  public locationNumber: string = '00000';
  public reportOrderServiceContact: string = 'Tier 1 - Acknowledge';
  public reportServiceEnvironmentContact: string = 'Tier 1 - Acknowledge';
  public reportTo: string = 'Alexandria Mall';
  public reportCC: string = 'CFA CARES';
  public reportActionGuidance: string = 'Needs Action - outside of 24 hour deadline';
  public orderPlacedVia: string = 'Mobile';
  public orderReceivedVia: string = 'Curbside';
  public receiptIncluded: string = 'YES';
  public orderNumber: string = '000000';
  public customerComments: string = '*** Report details were entered by Customer *** Took nearly 20 minutes and the employee came out ' +
    'slowly walking and swinging the bag of my 2 spicy chicken sandwiches around in the drizzling rain (was barely raining) and had such ' +
    'an attitude. Bag was soaking wet! I got home to eat the food and the quality and size is horrible. Taste like mchicken from ' +
    'McDonald\'s. Did the meat change?? Meat tasted fake or lab processed, the whole sandwich fit in the palm of my hand. Worst ' +
    'experience I ever had here. I took pictures.';
  public responseSentToCustomer: string = 'Approved response emailed on 02/09/2023 at 10:30pm: Dear Johnny, thank you for taking the ' +
    'time to share your feedback with our restaurant.';
  public reportResolutionStatus: string = 'Contact Not Resolved by CARES - See below for reasons.';
  public reportReasonUnableToResolve: string = 'Not authorized by Support Tier';
  public reportOfferStatus: string = 'No Care Offer Sent - Contact type did not qualify for a Care offer. Opportunity for ' +
    'Restaurant Recovery.';

  constructor(private analytics: AnalyticsService,
              private store: Store<fromRoot.State>,
              private router: Router,
              @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
              public dialogRef: MatDialogRef<ServiceNowReportComponent>) {
    this.isMobile$ = this.store.select(fromRoot.getIsMobile);
  }

  public closeReport() {
    this.dialogRef.close();
  }
}
