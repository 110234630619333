import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class CfaLoadingService {
    private shouldShow: Subject<boolean> = new Subject<boolean>();

    public showLoadingIndicator() {
        this.shouldShow.next(true);
    }

    public hideLoadingIndicator() {
        this.shouldShow.next(false);
    }

    public showLoadingChanged(): Observable<boolean> {
        return this.shouldShow.asObservable();
    }
}
